import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptedSell } from "../../redux/actions/dealerAction";

const AcceptedSell = () => {
  const dispatch = useDispatch();
  const { acceptedSell } = useSelector((state) => state.dealer);

  useEffect(() => {
    dispatch(getAcceptedSell());
  }, []);
  return (
    <div>
      <div className="items">
        <div className="buyCard owned">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
        </div>
        {acceptedSell?.map((item, index) => (
          <div key={index} className="buyCard owned">
            <div>
              <span className="key">Transaction ID</span>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <span className="key">Status</span> <span className={item.status} >{item.status} </span>
            </div>

            <div>
              <span className="key">Amount</span> <span>{item.amount}</span>{" "}
            </div>
            <div>
              <span className="key">Currency</span>{" "}
              <span>{item.currency?.currency}</span>{" "}
            </div>
            <div>
              <span className="key">Quantity</span>{" "}
              <span> {item.quantity}</span>{" "}
            </div>

            <div>
              <span className="key">Coin</span> <span>{item.coin?.name}</span>{" "}
            </div>
            <div>
              <span className="key">Country</span> <span>{item.country}</span>{" "}
            </div>
            <div>
              <span className="key">City:</span> <span>{item.city}</span>{" "}
            </div>

           
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcceptedSell;
