import React from "react";
import { Link } from "react-router-dom";

const UpHero = () => {
  return (
    <div className="up_hero_container py-4">
      <div className="page_container">
        <div className="container-fluid">
          <div className="row gy-4 align-items-center">
            <div className="col-12 col-md-8 px-0 position-relative">
              <h3 className="display-8  text-white">
                61 Countries, 45 Fiat Currencies, <h5>30 Crypto Assets</h5> and 1000
                Dealers <br /> <br />
                Most secure and trusted platform for hassle free transactions{" "}
                <br /> <br />
                Zero retention of customer data, post completion of transaction{" "}
                <br /> <br />
                0% Service Charges on most of our services <br /> <br />
                Cash Credited to bank account in   <h5>24 -48 hours</h5> <br /> <br />
              </h3>

              {/* <div className="d-none d-md-block trnsact_with_container">
                <h1 className="fw600 mb-2">We transact with</h1>
                <div className="d-flex justify-content-start gap-4">
                  <img src="/assets/transact/i1.png" alt="" />
                  <img src="/assets/transact/i2.png" alt="" />
                  <img src="/assets/transact/i3.png" alt="" />
                </div>
              </div> */}
            </div>
            <div className="col-12 col-md-4 px-0">
              <div className="row">
                <div className="col-8 col-lg-10 col-xl-12 mx-auto">
                  <img
                    className="w-100"
                    src="/assets/slider/i1.svg"
                    alt="Crypto Platform for all your needs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpHero;
