import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { kycOtpVerify } from "../../redux/actions/userAction";

const Verify = ({ step, setstep, userData }) => {
  const [otp, setotp] = useState("");
  const { otpVerified } = useSelector((state) => state.service);

  const dispatch = useDispatch();

  const verify = () => {
    dispatch(kycOtpVerify({ email: userData.email, otp }));
  };

  useEffect(() => {
    if (otpVerified) {
      setstep(step + 1);
    }
  }, [otpVerified]);

  return (
    <>
      <div className="col-12  ">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Verification Code
        </label>
        <br />
        <input
          type="phone"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="6 digit verification Code"
          value={otp}
          onChange={(e) => setotp(e.target.value)}
        />
      </div>

      <div className="col-12 ">
        <button
          className="form_submit_btn2 text-white p-3"
          onClick={verify}
          disabled={!otp}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default Verify;
