import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CreateAccount from "../components/Kyc/CreateAccount";
import PhotoVerification from "../components/Kyc/PhotoVerification";
import Selfie from "../components/Kyc/Selfie";
import Verify from "../components/Kyc/Verify";
import Layout from "../layout";

const KycRegistration = () => {
  const [step, setstep] = useState(0);
  const [userData, setuserData] = useState({});
  const history = useHistory();

  const { user, isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    setuserData(user);
  }, [user]);

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/sign-in");
    }
  }, [user, isAuthenticated]);

  useEffect(() => {
    if (user.kyc_verified) {
      return history.push("/profile");
    }
  }, [user, isAuthenticated]);

  const getComponent = (child) => {
    switch (step) {
      case 0:
        return (
          <CreateAccount
            userData={userData}
            setuserData={setuserData}
            step={step}
            setstep={setstep}
          />
        );
      case 1:
        return (
          <Verify
            userData={userData}
            setuserData={setuserData}
            step={step}
            setstep={setstep}
          />
        );
      case 2:
        return (
          <PhotoVerification
            step={step}
            setstep={setstep}
            userData={userData}
            setuserData={setuserData}
          >
            {" "}
            {child}{" "}
          </PhotoVerification>
        );
      case 3:
        return (
          <Selfie
            step={step}
            setstep={setstep}
            userData={userData}
            setuserData={setuserData}
          >
            {child}
          </Selfie>
        );
      default:
        break;
    }
  };
  return (
    <Layout>
      <Helmet>
        <title>Kyc Registration</title>
      </Helmet>
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div
              className="apply_loan_right_container p-4"
              style={{ maxWidth: "1000px", margin: "auto" }}
            >
              <div className="row gy-4">
                <h1>KYC Registration</h1>
                <div className="kyc_steps">
                  <div className="step" onClick={() => setstep(0)}>
                    1
                  </div>
                  <div className="step" onClick={() => setstep(1)}>
                    2
                  </div>
                  <div className="step" onClick={() => setstep(2)}>
                    3
                  </div>
                  <div className="step" onClick={() => setstep(3)}>
                    4
                  </div>
                </div>

                {getComponent()}

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default KycRegistration;
