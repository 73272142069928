import React, { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";

const Selfie = ({ step,imageData, setimageData, videoRef }) => {
  const photoRef = useRef();

  const stop = (e) => {
    const stream = videoRef.srcObject;
    const tracks = stream.getTracks();

    for (let i = 0; i < tracks.length; i++) {
      let track = tracks[i];
      track.stop();
    }

    videoRef.srcObject = null;
  };

  const takePhoto = () => {
    let photo = photoRef.current;
    let video = videoRef.current;
    const width = 400;
    const height = width / (16 / 9);

    photo.width = width;

    photo.height = height;

    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, width, height);

    const data = photo.toDataURL("image/jpeg");
    console.log(data);
    setimageData({ ...imageData, selfie: data });
  };
  return (
    <div className="card-verify">
      <h2 className="text-center">Take a Selfie</h2>
      <div className="photo-taker">
        {step == 3 && (
          <video
            className="selfie-placeholder"
            ref={videoRef}
            style={{ border: "2px solid" }}
          ></video>
        )}
        <canvas ref={photoRef} style={{ display: "none" }}></canvas>
        {imageData?.selfie && (
          <img
            className="selfie-placeholder"
            src={imageData?.selfie}
            alt=""
            style={{
              maxWidth: "200px",
              maxHeight: "200px",
              border: "2px solid",
            }}
          />
        )}
        {!imageData?.selfie ? (
          <div className="col-6 m-4">
            <button
              className="w-100 form_submit_btn2 text-white "
              onClick={takePhoto}
            >
              Take Selfie
            </button>
          </div>
        ) : (
          <div className="col-6 m-4">
            <button
              className="w-100 form_submit_btn2 text-white "
              onClick={() => setimageData({ ...imageData, selfie: null })}
            >
              Take Again
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Selfie;
