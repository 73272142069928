import React from "react";
import { toast } from "react-toastify";

const Upload = ({ children, onChange, src, onRemove, name }) => {
  const onChangeHandler = (e) => {
    const file = e.target.files[0];
    const mb = 1024 * 1023;
    if (file.size > mb) {
      return toast.warn("File is above 1 Mb,Compress and try again");
    } else {
      onChange(e);
    }
  };

  return (
    <div className="upload_container">
      {src ? (
        <img className="previewImage" src={src} alt="" onClick={onRemove} />
      ) : (
        <>
          {" "}
          <label
            className="w-100 d-flex align-items-center gap-3 justify-content-center"
            htmlFor="upload"
          >
            <img src="/assets/upload.svg" alt="" />
            <p className="text-white mb-0">{children}</p>
          </label>
          <input
            onChange={onChangeHandler}
            type="file"
            name={name}
            id="upload"
          />{" "}
        </>
      )}
    </div>
  );
};

export default Upload;
