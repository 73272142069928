import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import HowItWorks from "../components/HowItWorks";
import OtpBox from "../components/OtpBox";
import Select from "../components/Select";
import CashToCryptoTrack from "../components/Trackers/CashToCryptoTrack";
import CryptoToCashTrack from "../components/Trackers/CryptoToCashTrack";
import DepositTrack from "../components/Trackers/DepositTrack";
import EscrowTrack from "../components/Trackers/EscrowTrack";
import LendTrack from "../components/Trackers/LendTrack";
import LoanTrack from "../components/Trackers/LoanTrack";
import PaymentTrack from "../components/Trackers/PaymentTrack";
import SwapTrack from "../components/Trackers/SwapTrack";
import Layout from "../layout";
import {
  otpSend,
  otpVerify,
  trackApplication,
} from "../redux/actions/serviceAction";

const Track = () => {
  const [queryData, setqueryData] = useState({
    email: "",
    applicationId: "",
    type: "",
  });

  const [otp, setotp] = useState("");

  const { track, otpSent, otpVerified } = useSelector((state) => state.service);

  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!queryData.email) {
      return toast.warn("Email is required");
    }
    if (!queryData.applicationId) {
      return toast.warn("Application ID is required");
    }

    if (!queryData.type) {
      return toast.warn("Please select Application Type");
    }

    dispatch(
      trackApplication({
        email: queryData.email,
        id: queryData.applicationId,
        type: queryData.type,
      })
    );
  };

  const sendOtp = (e) => {
    e.preventDefault();
    if (!queryData.email) {
      return toast.error(" Email is required");
    }
    if (!queryData.applicationId) {
      return toast.error("Transaction ID is required");
    }

    dispatch(otpSend({ email: queryData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: queryData.email, otp }));
  };

  const getFields = () => {
    switch (queryData.type) {
      case "cash-loan":
        return <LoanTrack />;
      case "crypto-loan":
        return <LendTrack />;
      case "deposit":
        return <DepositTrack />;
      case "crypto-sell":
        return <CryptoToCashTrack />;
      case "crypto-buy":
        return <CashToCryptoTrack />;
      case "payment":
        return <PaymentTrack />;
      case "escrow":
        return <EscrowTrack />;

      case "swap":
        return <SwapTrack />;
      default:
        return <div></div>;
    }
  };

  return (
    <Layout  title="Track Your Transaction" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-0 text-white display-5 fw500">
                    Track Application
                  </h1>
                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Email
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="Email(That was used to create transaction) "
                        value={queryData.email}
                        onChange={(e) =>
                          setqueryData({ ...queryData, email: e.target.value })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Required"
                      >
                        Transaction ID
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Collateral Required"
                        id="Collateral Required"
                        className="inp_control"
                        value={queryData.id}
                        placeholder="Transaction ID "
                        onChange={(e) =>
                          setqueryData({
                            ...queryData,
                            applicationId: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-12">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Coin / Currency"
                      >
                        Application Type
                      </label>

                      {applications?.length > 0 && (
                        <Select
                          //   activeOption={loanData?.currency?.currency}
                          options={applications}
                          property="type"
                          dataChange={(item) =>
                            setqueryData({
                              ...queryData,
                              type: item.type,
                            })
                          }
                        />
                      )}
                    </div>
                    
                      {otpSent && !otpVerified && (
                        <OtpBox
                          value={otp}
                          onChange={(value) => setotp(value)}
                          onClick={verifyOtp}
                        />
                      )}
              

                    <div className="col-12 mt-5 d-flex justify-content-center">
                      {otpVerified && otpSent && (
                        <button
                          onClick={applyHandler}
                          className="form_submit_btn text-white"
                        >
                          Track
                        </button>
                      )}
                      {!otpSent && !otpVerified && (
                        <button
                          onClick={sendOtp}
                          className="form_submit_btn text-white"
                          disabled={otpSent}
                        >
                          Send Otp
                        </button>
                      )}
                    </div>
                  </div>

                  {track && (
                    <div className="mt-5">
                      <div className="row gy-4">{getFields()}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>: Find your
                    transactions by furnishing the details
                    <br />
                    <span className="color4"> 2</span>: All the details
                    mentioned are for reference purpose only and subjected to
                    final confirmation from 99krypto
                    <br />
                    <span className="color4"> 3</span>: Part Payments and
                    interest payments will be updated on regular basis and may
                    not be updated immediately
                    <br />
                    <span className="color4"> 4</span>:For any enquiries
                    please contact us at support@99krypto.com
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Track;

const applications = [
  {
    type: "cash-loan",
  },
  {
    type: "crypto-loan",
  },
  {
    type: "deposit",
  },

  {
    type: "crypto-sell",
  },
  {
    type: "crypto-buy",
  },
  {
    type: "swap",
  },
  {
    type: "payment",
  },
  {
    type: "escrow",
  },
];
