// CSS
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./css/styles.css";

// This is a React Router v6 app
import { Switch, Route } from "react-router-dom";
import { useEffect } from "react";
import { ethers } from "ethers";
import Web3Modal from "web3modal";

// PAGES
import Home from "./pages/Home";
import ApplyLoan from "./pages/ApplyLoan";
import CryptoCash from "./pages/CryptoCash";
import FixedDeposit from "./pages/FixedDeposit";
import EscrowService from "./pages/EscrowService";
import Dealer from "./pages/Dealer";
import PaymentService from "./pages/PaymentService";
import SubmitPage from "./pages/SubmitPage";
import { useDispatch } from "react-redux";
import {
  getCryptoCoins,
  getCurrency,
  getSetting,
  getTimePeriods,
  getWallets,
} from "./redux/actions/serviceAction";
import Dashboard from "./pages/Dashboard";
import Track from "./pages/Track";
import ApplyLend from "./pages/ApplyLend";
import CryptoSwap from "./pages/CryptoSwap";
import About from "./pages/About";
import ChangePassword from "./pages/ChangePassword";
import ForgetPassword from "./pages/ForgetPassword";
import ResetPassword from "./pages/ResetPassword";
import Pdfview from "./pages/Pdfview";
import KycRegistration from "./pages/KycRegistration";
import Register from "./pages/Register";
import Login from "./pages/Login";
import Account from "./pages/Account";

function App() {
  const urls = [
    {
      route: "/",
      page: Home,
    },
    {
      route: "/cash-loans",
      page: ApplyLoan,
    },
    {
      route: "/crypto-loans",
      page: ApplyLend,
    },
    {
      route: "/buy-sell",
      page: CryptoCash,
    },
    {
      route: "/deposits",
      page: FixedDeposit,
    },
    {
      route: "/escrow",
      page: EscrowService,
    },
    {
      route: "/dealer",
      page: Dealer,
    },
    {
      route: "/payment-services",
      page: PaymentService,
    },
    {
      route: "/submit-thankyou",
      page: SubmitPage,
    },
    {
      route: "/dashboard",
      page: Dashboard,
    },
    {
      route: "/find",
      page: Track,
    },
    {
      route: "/swap",
      page: CryptoSwap,
    },
    {
      route: "/about-us",
      page: About,
    },
    {
      route: "/change-password",
      page: ChangePassword,
    },
    {
      route: "/forget-password",
      page: ForgetPassword,
    },
    {
      route: "/reset-password/:token",
      page: ResetPassword,
    },
    {
      route: "/white-paper",
      page: Pdfview,
    },
    {
      route: "/kyc-registration",
      page: KycRegistration,
    },
    {
      route: "/sign-up",
      page: Register,
    },
    {
      route: "/sign-in",
      page: Login,
    },
    {
      route: "/profile",
      page: Account,
    },
  ];

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrency());
    dispatch(getCryptoCoins());
    dispatch(getTimePeriods());
    dispatch(getWallets());
    dispatch(getSetting());
  }, []);

  const providerOptions = {};

  async function connectWallet() {
    try {
      const web3Modal = new Web3Modal({
        cacheProvider: false,
        network: "mainnet", //
        providerOptions,
      });
      const web3ModlInstance = await web3Modal.connect();

      const web3ModalProvider = new ethers.providers.Web3Provider(
        web3ModlInstance
      );
      console.log(web3ModalProvider);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Switch>
      {urls.map((item, i) => {
        return <Route key={i} exact path={item.route} component={item.page} />;
      })}
    </Switch>
  );
}

export default App;
