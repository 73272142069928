import React, { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Modal from "../components/Modal";
import Navigation from "../components/Navigation";
import Sidebar from "../components/Sidebar";
import Spinner from "../components/Spinner";

const Layout = (props) => {
  const { title, children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const { loading } = useSelector((state) => state.service);
  const [modalOpen, setmodalOpen] = useState(true);

  const { pathname } = useLocation();

  const OnClick = () => {
    setIsOpen(!isOpen);
  };

  const closeModal = () => {
    setmodalOpen(false);
    localStorage.setItem("modalOpen", "false");
  };

  useEffect(() => {
    const _d = localStorage.getItem("modalOpen");
    if (_d) {
      setmodalOpen(false);
    }
    if (pathname == "/") {
      setmodalOpen(false);
    } else if (pathname == "/about-us") {
      setmodalOpen(false);
    } else if (pathname == "/white-paper") {
      setmodalOpen(false);
    }

    window.addEventListener("load", () => {
      localStorage.removeItem("modalOpen");
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> {`${title}`} </title>
      </Helmet>
      <div className="top_navigation">
        <Modal modalOpen={modalOpen} closeModal={closeModal} />
        <Header />
        <Navigation ClickEvent={OnClick} isOpen={isOpen} />
      </div>
      <div className="under_navigation"></div>
      <Sidebar ClickEvent={OnClick} isOpen={isOpen} />
      {loading ? <Spinner /> : <> {children} </>}
    </>
  );
};

export default Layout;
