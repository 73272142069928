import React from "react";
import { useSelector } from "react-redux";

const SwapTrack = () => {
  const { track: swapData } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Status
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={swapData.status}
        />
      </div>
    

      <div className="col-12 col-md-6">
        <label className="text-white mb-1 fw600" htmlFor="Collateral Required">
         Swap Coin
        </label>
        <br />
        <input
          type="text"
          name="Collateral Required"
          id="Collateral Required"
          className="inp_control"
          value={`${swapData.crypto?.name} `}
          placeholder="Interest rate"
          disabled
        />
      </div>

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
         Swap Quantity
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={swapData.quantity}
        />
      </div>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
        Recieving Crypto
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name.."
          value={swapData.receivingCrypto?.name}
          disabled
        />
      </div>

      

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Receiving Quantity
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Amount.."
          value={swapData.receivingQuantity}
          disabled={true}
        />
      </div>

     
    </>
  );
};

export default SwapTrack;
