import { useState } from "react";
import { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-toastify";

const Contact = () => {
  const form = useRef();
  const [data, setdata] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });

  const sendEmail = (e) => {
    e.preventDefault();

    if (!data.first_name) {
      return toast.warn("First name is required");
    }
    if (!data.last_name) {
      return toast.warn("Last name is required");
    }
    if (!data.email) {
      return toast.warn("Email is required");
    }
    if (!data.phone) {
      return toast.warn("Phone is required");
    }
    if (!data.message) {
      return toast.warn("Please write your message");
    }

    emailjs
      .sendForm(
        "godaddy_webmail",
        "template_85v6usq",
        form.current,
        "wX4krLu_KdqDVKprM"
      )
      .then(
        (result) => {
          setdata({
            first_name: "",
            last_name: "",
            email: "",
            phone: "",
            message: "",
          });
          toast.success("Thank you ,your message has been sent to Support)");
          form.current = {};
        },
        (error) => {
          toast.error("Something went wrong.Please try again");
        }
      );
  };

  return (
    <div className="contact_container">
      <div className="page_container">
        <div className="container-fluid">
          <div className="bg_color1 rounded-3">
            <div className="row">
              <div className="col-12 col-md-6 p-md-4 pe-md-0 py-4">
                <h1 className="text-center mb-4 text-white fw600">
                  Contact us
                </h1>
                <form className="row gy-3 px-4" ref={form} onSubmit={sendEmail}>
                  <div className="col-6">
                    <input
                      type="text"
                      name="first_name"
                      id=""
                      value={data.first_name}
                      className="form-control"
                      placeholder="Enter First Name"
                      onChange={(e) =>
                        setdata({ ...data, first_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-6">
                    <input
                      type="text"
                      name="last_name"
                      value={data.last_name}
                      id=""
                      className="form-control"
                      placeholder="Enter Last Name"
                      onChange={(e) =>
                        setdata({ ...data, last_name: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="email"
                      value={data.email}
                      id=""
                      className="form-control"
                      placeholder="Enter Email"
                      onChange={(e) =>
                        setdata({ ...data, email: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-12">
                    <input
                      type="text"
                      name="phone"
                      value={data.phone}
                      id=""
                      className="form-control"
                      placeholder="Enter Phome Number"
                      onChange={(e) =>
                        setdata({ ...data, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      name="message"
                      value={data.message}
                      id=""
                      cols="30"
                      rows="10"
                      className="w-100 form-control"
                      placeholder="Enter message"
                      onChange={(e) =>
                        setdata({ ...data, message: e.target.value })
                      }
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn w-100 submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-6">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d99580.00554143201!2d-9.160203249999999!3d38.74362659999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd19331a61e4f33b%3A0x400ebbde49036d0!2sLisbon%2C%20Portugal!5e0!3m2!1sen!2s!4v1652262079808!5m2!1sen!2s"
                  width="100%"
                  height="100%"
                  style={{ border: "0", borderRadius: "0px 6px 6px 0px" }}
                  allowFullScreen=""
                  loading="lazy"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
