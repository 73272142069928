import React, { useState } from "react";
import PasswordStrengthBar from "react-password-strength-bar";
import img from "../assets/eye.svg";
const Password = ({ value, onChange, placeholder }) => {
  const [isShow, setIsShow] = useState(false);
  const [score, setscore] = useState(0);

  return (
    <>
      <div className="pass_inp_container position-relative">
        <input
          type={`${(isShow && "text") || "password"}`}
          name="Email  ( Same as provided for FD )"
          id="Email  ( Same as provided for FD )"
          className="inp_control"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />

        <img
          onClick={() => setIsShow(!isShow)}
          className="eye pointer"
          src={img}
          alt=""
        />
      </div>
      <PasswordStrengthBar
        password={value}
        onChangeScore={(score, feedback) => setscore(score, "GOOD")}
      />
    </>
  );
};

export default Password;
