import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getPayments,
  takePaymentOrder,
} from "../../redux/actions/dealerAction";

const Payments = () => {
  const dispatch = useDispatch();
  const { payments } = useSelector((state) => state.dealer);
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getPayments());
  }, []);

  const clickHandler = (id) => {
    dispatch(takePaymentOrder(id));
  };

  return (
    <div>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div> Amount</div>
          <div>Crypto</div>
          <div>To be paid</div>
          <div>Payment Mode</div>
          <div>Country</div>
          <div>City</div>
          <div>Take Order</div>
        </div>
        {payments?.map((item, index) => (
          <div key={index} className="buyCard">
            <div>
              <strong className="key">Transation ID :</strong>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <strong className="key">Status:</strong>{" "}
              <span> {item.status}</span>{" "}
            </div>

            <div>
              <strong className="key"> Amount:</strong>{" "}
              <span>{item.amount}</span>{" "}
            </div>
            <div>
              <strong className="key"> Crypto:</strong>{" "}
              <span>{item.coin?.name} </span>{" "}
            </div>
            <div>
              <strong className="key">To be paid:</strong>{" "}
              <span>{item.totalTobePaid} </span>{" "}
            </div>
            <div>
              <strong className="key">Interest:</strong>{" "}
              <span>{item.paymentMode} </span>{" "}
            </div>

            <div>
              <strong className="key">Country:</strong>
              <span> {item.country}</span>{" "}
            </div>
            <div>
              <strong className="key">City:</strong> <span>{item.city}</span>{" "}
            </div>

            <div>
              <button
                className="btn btn-primary"
                onClick={() => clickHandler(item._id)}
              >
                Take order
              </button>{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Payments;
