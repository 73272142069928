import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Password from "../components/Password";
import Layout from "../layout";
import { register } from "../redux/actions/userAction";

const Register = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {isAuthenticated}=useSelector(state=>state.user)

  const [userData, setuserData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    passwored: "",
    confirmPassword: "",
    phoneNo: "",
  });

  const submitHandler = () => {
    if (
      !userData.firstName ||
      !userData.lastName ||
      !userData.email ||
      !userData.password ||
      !userData.confirmPassword ||
      !userData.phoneNo
    ) {
      return toast.warn("All fields are required");
    }

    dispatch(register(userData));
  };


  useEffect(() => {
    if (isAuthenticated) {
      return history.push("/dashboard");
    }
  }, [isAuthenticated]);

  return (
    <Layout title="Sign Up">
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="register">
            <h3 className="text-center  mb-3 text-white display-5 fw500">
              Sign Up
            </h3>
            <div className="row gy-1">
              <div className="col-6">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Email  ( Same as provided for FD )"
                >
                  First Name
                </label>
                <br />
                <input
                  type="text"
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.firstName}
                  autoComplete={false}
                  placeholder="Your first name"
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      firstName: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-6">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Email  ( Same as provided for FD )"
                >
                  Last Name
                </label>
                <br />
                <input
                  type="text"
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.lastName}
                  placeholder="Your last name"
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      lastName: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Email  ( Same as provided for FD )"
                >
                  Email
                </label>
                <br />
                <input
                  type="text"
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.email}
                  placeholder="Your email address"
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      email: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Your Mobile number"
                >
                  Phone Number
                </label>
                <br />
                <input
                  type="text"
                  name="name"
                  id=""
                  className="inp_control"
                  placeholder="Your mobile Number"
                  value={userData.phoneNo}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      phoneNo: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Choose a strong password"
                >
                  Password
                </label>
                <br />
                <Password
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.password}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      password: e.target.value,
                    })
                  }
                />
              </div>
              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Repeat password"
                >
                  Confirm Password
                </label>
                <br />
                <Password
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.confirmPassword}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      confirmPassword: e.target.value,
                    })
                  }
                />
              </div>

              <button
                className="w-100 form_submit_btn2 text-white"
                onClick={submitHandler}
              >
                Sign Up
              </button>
              <p  className="text-center text-white" >Already Have an account ? <Link  to="/sign-in" > <a  className="fw600 color4 m-1" >Sign In</a> </Link> </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Register;
