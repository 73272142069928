import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  loanOrderSuccess,
  paymentOrderSuccess,
  takenPaymentOrder,
} from "../../redux/actions/dealerAction";
import DetailsModal from "../DetailsModal";
import Upload from "../Upload";

const TakenPaymets = () => {
  const dispatch = useDispatch();
  const { takenPayment } = useSelector((state) => state.dealer);
  const [modalOpen, setmodalOpen] = useState(false);
  const [successModal, setsuccessModal] = useState(false);
  const [screenshot, setscreenshot] = useState(null);

  useEffect(() => {
    dispatch(takenPaymentOrder());
  }, []);

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenshot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const markPaymentOrderSuccess = (id) => {
    if (!screenshot) {
      return toast.warn("Please attach Screenshot");
    }

    const form = new FormData();

    form.set("screenShot", screenshot);
    dispatch(paymentOrderSuccess(id, form));

    setsuccessModal(false);
  };

  return (
    <div>
      <div className="items">
        <div className="buyCard ">
          <div>Transaction ID</div>
          <div>Status</div>
          <div>Currency</div>

          <div> Amount</div>
          <div>To be paid</div>
          <div> Crypto</div>

          <div>Country,City</div>
          <div>Bank Details</div>
          <div>Action</div>
        </div>
        {takenPayment?.map((item, index) => (
          <div key={index} className="buyCard">
            <DetailsModal
              modalOpen={modalOpen}
              closeModal={() => setmodalOpen(false)}
            >
              <div>
                <label htmlFor="">Bank Details</label>
                <h4>{item.bankDetails}</h4>
              </div>
            </DetailsModal>
            <DetailsModal
              modalOpen={successModal}
              closeModal={() => setsuccessModal(false)}
            >
              <div>
                <label htmlFor="">Screenshot of Transfer</label>

                <div className="bg-primary">
                  <Upload onChange={fileChange} src={screenshot}>
                    <div className="d-none d-md-block bg-primary ">
                      Drag & Drop your Files Here
                    </div>
                    <div className="d-block d-md-none">Attach</div>
                  </Upload>
                </div>

                <button
                  className="  m-3 form_submit_btn text-white"
                  onClick={() => markPaymentOrderSuccess(item._id)}
                >
                  Mark As Success
                </button>
              </div>
            </DetailsModal>
            <div>
              <strong className="key">Transation ID :</strong>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <strong className="key">Status:</strong>
              <span  className={item.status} >{item.status}</span>{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>
              <span>{item.currency.currency}</span>{" "}
            </div>

            <div>
              <strong className="key"> Amount:</strong>{" "}
              <span>{item.amount}</span>{" "}
            </div>
            <div>
              <strong className="key">To be paid:</strong>{" "}
              <span>{item.totalTobePaid} </span>{" "}
            </div>
            <div>
              <strong className="key"> Crypto:</strong>{" "}
              <span>{item.coin?.name} </span>{" "}
            </div>

            <div>
              <strong className="key">Country,City:</strong>
              <span>
                {item.country}, <br /> {item.city}
              </span>{" "}
            </div>
            <div>
              <strong className="key">Bank Details:</strong>{" "}
              <span>{item.city}</span>{" "}
              <button
                className="btn btn-primary"
                onClick={() => setmodalOpen(true)}
              >
                Bank Details
              </button>
            </div>

            <div>
              {item.status == "pending" ?  <button
                className="btn btn-primary"
                onClick={() => setsuccessModal(true)}
              >
                Mark Success
              </button>:<button className="btn btn-primary disabled" >Submitted</button> }
             
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TakenPaymets;
