import React from "react";
import { navigations } from "./navigations";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";

import { networkParams } from "../web3/networks";
import { toHex, truncateAddress } from "../web3/utils";
import { ethers } from "ethers";
import Web3Modal from "web3modal";
import { providerOptions } from "../web3/providerOptions";

const web3Modal = new Web3Modal({
  network: "rinkeby", // optional
  cacheProvider: true, // optional
  isableInjectedProvider: false,
  providerOptions, // required,
  theme: "dark",
});

const Navigation = ({ ClickEvent, isOpen }) => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  // FOR NAVBAR SCROLLING EFFECT START
  window.addEventListener("scroll", function () {
    var header = document.querySelector(".navigation_container");
    if (header !== null) {
      header.classList.toggle("sticky", window.scrollY > 10);
    }
  });

  // Web 3 states

  const [provider, setProvider] = useState();
  const [library, setLibrary] = useState();
  const [account, setAccount] = useState();
  const [signature, setSignature] = useState("");
  const [error, setError] = useState("");
  const [chainId, setChainId] = useState();
  const [network, setNetwork] = useState();
  const [message, setMessage] = useState("");
  const [signedMessage, setSignedMessage] = useState("");
  const [verified, setVerified] = useState();

  const connectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      const library = new ethers.providers.Web3Provider(provider);
      const accounts = await library.listAccounts();
      const network = await library.getNetwork();
      setProvider(provider);
      setLibrary(library);
      if (accounts) setAccount(accounts[0]);
      setChainId(network.chainId);
    } catch (error) {
      setError(error);
    }
  };

  const handleNetwork = (e) => {
    const id = e.target.value;
    setNetwork(Number(id));
  };

  const handleInput = (e) => {
    const msg = e.target.value;
    setMessage(msg);
  };

  const switchNetwork = async () => {
    try {
      await library.provider.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: toHex(network) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await library.provider.request({
            method: "wallet_addEthereumChain",
            params: [networkParams[toHex(network)]],
          });
        } catch (error) {
          setError(error);
        }
      }
    }
  };

  const signMessage = async () => {
    if (!library) return;
    try {
      const signature = await library.provider.request({
        method: "personal_sign",
        params: [message, account],
      });
      setSignedMessage(message);
      setSignature(signature);
    } catch (error) {
      setError(error);
    }
  };

  const verifyMessage = async () => {
    if (!library) return;
    try {
      const verify = await library.provider.request({
        method: "personal_ecRecover",
        params: [signedMessage, signature],
      });
      setVerified(verify === account.toLowerCase());
    } catch (error) {
      setError(error);
    }
  };

  const refreshState = () => {
    setAccount();
    setChainId();
    setNetwork("");
    setMessage("");
    setSignature("");
    setVerified(undefined);
  };

  const disconnect = async () => {
    await web3Modal.clearCachedProvider();
    refreshState();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider) {
      connectWallet();
    }
  }, []);

  useEffect(() => {
    if (provider?.on) {
      const handleAccountsChanged = (accounts) => {
        console.log("accountsChanged", accounts);
        if (accounts) setAccount(accounts[0]);
      };

      const handleChainChanged = (_hexChainId) => {
        setChainId(_hexChainId);
      };

      const handleDisconnect = () => {
        console.log("disconnect", error);
        disconnect();
      };

      provider.on("accountsChanged", handleAccountsChanged);
      provider.on("chainChanged", handleChainChanged);
      provider.on("disconnect", handleDisconnect);

      return () => {
        if (provider.removeListener) {
          provider.removeListener("accountsChanged", handleAccountsChanged);
          provider.removeListener("chainChanged", handleChainChanged);
          provider.removeListener("disconnect", handleDisconnect);
        }
      };
    }
  }, [provider]);

  return (
    <div className="navigation_container">
      <div className="page_container">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <ul className="mb-0 d-none d-xl-flex list-unstyled">
              {navigations.map((content, i) => {
                return (
                  <NavLink
                    exact
                    activeClassName="active_navlink"
                    key={i}
                    to={content.link}
                    className="text-decoration-none text-white px-3 py-2"
                  >
                    <li>{content.text}</li>
                  </NavLink>
                );
              })}
            </ul>

            <div className="header_btns d-flex py-1 gap-2">
              {isAuthenticated ? (
                <button className="px-2 bg_color1 rounded-3 text-white border-0">
                  {user?.role == "dealer" ? (
                    <Link
                      className="text-white text-decoration-none"
                      to="/dashboard"
                    >
                      Dashboard
                    </Link>
                  ) : (
                    <Link
                      className="text-white text-decoration-none"
                      to="/profile"
                    >
                      Account
                    </Link>
                  )}
                </button>
              ) : (
                <></>
              )}

              {!isAuthenticated ? (
                <button className="px-2 bg_color1 rounded-3 text-white border-0">
                  {user?.role == "dealer" ? (
                    <Link
                      className="text-white text-decoration-none"
                      to="/dealer"
                    >
                      Dealer Login
                    </Link>
                  ) : (
                    <Link
                      className="text-white text-decoration-none"
                      to="/sign-in"
                    >
                      Sign In
                    </Link>
                  )}
                </button>
              ) : (
                <></>
              )}

              <button className="px-2" onClick={connectWallet}>
                Connect wallet
              </button>
            </div>

            <div className="d-block d-xl-none">
              {(isOpen && (
                <img
                  className="hamb_cross"
                  onClick={ClickEvent}
                  src="/assets/cross.svg"
                  alt=""
                />
              )) || (
                <img
                  className="hamb_cross"
                  onClick={ClickEvent}
                  src="/assets/hamberger.svg"
                  alt=""
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
