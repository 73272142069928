import Layout from "../layout";
import {Helmet} from "react-helmet";

const Pdfview = () => {
  return (
    <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>White Paper</title>
               
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
      <div className="pdfContainer">
        <iframe
          src="/assets/whitepaper.pdf"
          frameBorder={1}
          title="White Paper"
          scrolling="auto"
        ></iframe>
      </div>
    </Layout>
  );
};

export default Pdfview;
