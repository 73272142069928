import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

const Sidebar = ({ isOpen, ClickEvent }) => {
  const { isAuthenticated } = useSelector((state) => state.user);
  const navigations = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "Cash Loans",
      link: "/cash-loans",
    },
    {
      text: "Crypto Loans",
      link: "/crypto-loans",
    },
    {
      text: "Deposits",
      link: "/deposits",
    },
    {
      text: "Buy/Sell",
      link: "/buy-sell",
    },
    {
      text: "Swap",
      link: "/swap",
    },
    {
      text: "Payments",
      link: "/payment-services",
    },
    {
      text: "Escrow",
      link: "/escrow",
    },
    {
      text: "Find Transaction",
      link: "/find",
    },
    {
      text: "Press",
      link: "/about-us",
    },
    {
      text: "White Paper",
      link: "/white-paper",
    },
  ];

  return (
    <div
      onClick={ClickEvent}
      style={{
        opacity: `${isOpen ? "1" : "0"}`,
        top: `${isOpen ? "0%" : "-100%"}`,
      }}
      className="SideBarContainer"
    >
      <ul>
        {navigations.map((item, i) => {
          return (
            <li className="fw600 text-white pointer" key={i}>
              <NavLink exact activeClassName="active" to={item.link}>
                {item.text}
              </NavLink>
            </li>
          );
        })}

        <div className="header_btns d-flex flex-column py-1 gap-3">
          {isAuthenticated ? (
            <button className="py-2 px-2 bg_color1 rounded-3 text-white border-0">
              <Link to="/dealer" className="text-white text-decoration-none">
                Dashboard
              </Link>
            </button>
          ) : (
            <button className="py-2 px-2 bg_color1 rounded-3 text-white border-0">
              <Link to="/dealer" className="text-white text-decoration-none">
                Dealer Login
              </Link>
            </button>
          )}
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
