import React, { useState } from "react";

const Checkbox = ({ onClick, active }) => {
  return (
    <div onClick={onClick} className="position-relative pointer">
      {(!active && <img src="assets/uncheck.svg" alt="" />) || (
        <img src="assets/check.svg" alt="" />
      )}
    </div>
  );
};

export default Checkbox;
