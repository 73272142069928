import React from "react";
import { useSelector } from "react-redux";

const CashToCryptoTrack = () => {
  const { track: cashToCryptoData } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Status
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={cashToCryptoData.status}
        />
      </div>
      <div className="col-12 col-md-6">
        <label className="text-white mb-1 fw600" htmlFor="Collateral Required">
          Buy Coin
        </label>
        <br />
        <input
          type="text"
          name="Collateral Required"
          id="Collateral Required"
          className="inp_control"
          value={cashToCryptoData.coin?.name}
          placeholder="Interest rate"
          disabled
        />
      </div>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Buy Quantity
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={cashToCryptoData.quantity}
        />
      </div>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Payable Currency
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Currency.."
          value={cashToCryptoData?.currency?.currency}
          disabled={true}
        />
      </div>

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Payable Amount
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Amount.."
          value={cashToCryptoData.amount}
          disabled={true}
        />
      </div>

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Country
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name.."
          value={cashToCryptoData.country}
          disabled
        />
      </div>

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          City
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name.."
          value={cashToCryptoData.city}
          disabled
        />
      </div>
    </>
  );
};

export default CashToCryptoTrack;
