import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Layout from "../layout";
import {} from "../redux/actions/serviceAction";
import { updatePassword } from "../redux/actions/userAction";

const ChangePassword = () => {
  const [oldPassword, setoldPassword] = useState("");
  const [newPassword, setnewPassword] = useState("");



  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!oldPassword) {
      return toast.warn("Old Password is required");
    }
    if (!newPassword) {
      return toast.warn("New Password ID is required");
    }

    dispatch(
      updatePassword({
        oldPassword,
        newPassword,
        confirmPassword: newPassword,
      })
    );
  };

  const { user, isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/dealer");
    }
  }, [isAuthenticated]);

  return (
    <Layout  title="Change Password" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-0 text-white display-5 fw500">
                    Change Password
                  </h1>
                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Old Password
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="Old password.. "
                        value={oldPassword}
                        onChange={(e) => setoldPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        New Password
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="New Password "
                        value={newPassword}
                        onChange={(e) => setnewPassword(e.target.value)}
                      />
                    </div>

                    <div className="col-12 mt-5 d-flex justify-content-center">
                      <button
                        onClick={applyHandler}
                        className="form_submit_btn text-white"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ChangePassword;
