import React, { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const BackCardVerify = ({
  step,
  setstep,
  videoRef,
  imageData,
  setimageData,
  children,
}) => {
  const photoRef = useRef();

  // const stop = (e) => {
  //   const stream = videoRef.srcObject;
  //   const tracks = stream.getTracks();

  //   for (let i = 0; i < tracks.length; i++) {
  //     let track = tracks[i];
  //     track.stop();
  //   }

  //   videoRef.srcObject = null;
  // };

  // useEffect(() => {
  //   stop();
  // }, [step]);

  const takePhoto = () => {
    let photo = photoRef.current;
    let video = videoRef.current;
    const width = 400;
    const height = width / (16 / 9);

    photo.width = width;

    photo.height = height;

    let ctx = photo.getContext("2d");

    ctx.drawImage(video, 0, 0, width, height);

    const data = photo.toDataURL("image/jpeg");
    console.log(data);
    setimageData({ ...imageData, idBack: data });
  };

  return (
    <div className="card-verify">
      <h2 className=" text-center ">Back Part of your ID</h2>

      <div className="photo-taker">
        {!imageData.idBack ? (
          <button
            className="w-100 form_submit_btn2 text-white m-4"
            onClick={takePhoto}
          >
            Take Photo
          </button>
        ) : (
          <button
            className="w-100 form_submit_btn2 text-white m-4"
            onClick={() => setimageData({ ...imageData, idBack: null })}
          >
            Take Again
          </button>
        )}

        <canvas style={{ display: "none" }} ref={photoRef}></canvas>

        {imageData.idBack && (
          <img className="display_image m-3" src={imageData.idBack} alt="" />
        )}
      </div>
    </div>
  );
};

export default BackCardVerify;
