import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const Sell = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);

  const [securityCode, setsecurityCode] = useState("");

 

  const isBlocked = (item) => {
    const length = item?.tried.filter((i) => i == user._id).length;

    return length >= 3 ? true : false;
  };
  return (
    <div>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>

          <div>Accept</div>
        </div>
        {user.sell?.map((item, index) => (
          <div key={index} className="buyCard ">
            <div>
              {" "}
              <strong className="key">Transaction ID:</strong>{" "}
              {item.transactionId}{" "}
            </div>
            <div>
              {" "}
              <strong className="key">Status:</strong> {item.status}{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>
              {item.amount}{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>
              {item.currency?.currency}{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>
              {item.quantity.toFixed(5)}{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>
              {item.coin?.name}{" "}
            </div>
            <div>
              <strong className="key">Country:</strong> {item.country}{" "}
            </div>
            <div>
              <strong className="key">City:</strong> {item.city}{" "}
            </div>
            {isBlocked(item) ? (
              <div>
                {" "}
                <button className="btn btn-danger">Blocked</button>{" "}
              </div>
            ) : (
              <div>
                <input
                  value={securityCode}
                  type="number"
                  placeholder="Security Code"
                  onChange={(e) => setsecurityCode(e.target.value)}
                />
                <button className="btn btn-primary">Accept</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Sell;
