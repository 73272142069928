import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { forgotPassword } from "./../redux/actions/userAction";
import HowItWorks from "../components/HowItWorks";

import Layout from "../layout";

const ForgetPassword = () => {
  const [email, setemail] = useState("");
  const [clicked, setclicked] = useState(false);

  const dispatch = useDispatch();

  const applyHandler = () => {
    if (!email) {
      return toast.warn("Email is required");
    }

    const form = new FormData();

    form.set("email", email);

    dispatch(forgotPassword(form));

    setclicked(true);
  };

  return (
    <Layout title="Forget Password">
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-6">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-0 text-white display-5 fw500">
                    Reset Password
                  </h1>
                  <br />
                  {!clicked ? (
                    <div className="row gy-4">
                      <div className="col-12 ">
                        <label
                          className="text-white mb-1 fw600"
                          htmlFor="Loan Amount"
                        >
                          Email
                        </label>
                        <br />
                        <input
                          type="text"
                          name="Loan Amount"
                          id="Loan Amount"
                          className="inp_control"
                          placeholder="Email(That was used to create account) "
                          value={email}
                          onChange={(e) => setemail(e.target.value)}
                        />
                      </div>

                      <div className="col-12 mt-5 d-flex justify-content-center">
                        <button
                          onClick={applyHandler}
                          className="form_submit_btn text-white"
                        >
                          Send Reset Password Link
                        </button>
                      </div>
                    </div>
                  ) : (
                    <h4>
                      Please check your email <br /> ! A reset password link has
                      been sent in your email address{" "}
                    </h4>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>: Fill the form and choose
                    the loan period to check the total amount need to returned
                    back at the time of closing the loan .Send the collateral
                    coins to the crypto wallet mentioned. Attach the screenshot
                    of the same and complete the submission. We will send you a
                    confirmation email with in 6 hours
                    <br />
                    <br />
                    <span className="color4"> 2</span>: your loan amount will be
                    transferred to your Bank Account or Western Union Account
                    with in 24 to 48 working hours. How ever there may be
                    further delay from your bank in few cases. In such delay ,
                    Please contact your bank for further updates.
                    <br />
                    <br />
                    <h4 className="text-danger">Important Information</h4>
                    <li>
                      {" "}
                      Loan processing fee of 5% of loan amount is deducted
                      before transferring the loan amount as Currency conversion
                      ,Bank transfer charges and other applicable taxes are to
                      be borne by the company{" "}
                    </li>
                    <br />
                    <li>
                      Pre-payments can be done, how ever preclosure of Loans is
                      allowed only after the complete loan payback amount is
                      paid ,which is mentioned at the time of applying
                    </li>
                    <br />
                    <li>
                      After closing the loan your collateral crypto coins will
                      be transferred to your wallet . Wallet Adress will be
                      reconfirmed by an email before transferring the crypto.
                    </li>
                    <br />
                    <li>
                      {" "}
                      Monthly Interest payment is not mandate, however to reduce
                      the risk of liquidation due to price dip you can do pre-
                      payments{" "}
                    </li>
                    <br />
                    <li>
                      {" "}
                      How to do pre payment? information will be furnished in
                      the Email which will be sent to you after transferring
                      your loan amount.
                    </li>
                    <li>
                      {" "}
                      As it is a high Leveraged Loan , Please make sure your
                      Collateral asset never drops below110% of Loan value
                      including interest in occurred till date. If the asset
                      value goes below it, we may take a call to sell the asset
                      and close the Loan account automatically and if any
                      balance left will be deposited back to your wallet
                    </li>
                    <br />
                    <li>
                      {" "}
                      In few cases we may ask your Identification and other
                      documents to verify if necessary.
                    </li>
                    <br />
                    <li>
                      For any questions please feel free to reach us through
                      contact us form. We will get back to you within 24 hours
                      by email or through phone call if provided{" "}
                    </li>
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ForgetPassword;

const applications = [
  {
    type: "cash-loan",
  },
  {
    type: "crypto-loan",
  },
  {
    type: "deposit",
  },

  {
    type: "crypto-sell",
  },
  {
    type: "crypto-buy",
  },
  {
    type: "swap",
  },
  {
    type: "crypto-payments",
  },
  {
    type: "escrow",
  },
];
