import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Checkbox from "../components/Checkbox";
import { Link, useHistory } from "react-router-dom";
import Upload from "../components/Upload";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  applyCashToCrypto,
  applyCryptoToCash,
  otpSend,
  otpVerify,
} from "../redux/actions/serviceAction";
import { toast } from "react-toastify";
import OtpBox from "../components/OtpBox";
import { BsCheck2 } from "react-icons/bs";

const CryptoCash = () => {
  const dispatch = useDispatch();
  const [addContent, setAddContent] = useState(false);
  const [addContent2, setAddContent2] = useState(false);
  const [isCopied, setisCopied] = useState(false);
  const [screenShot, setscreenShot] = useState(null);
  const [screenShot2, setscreenShot2] = useState(null);
  const history = useHistory();

  const [checked, setchecked] = useState("crypto");
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");
  const [countries, setcountries] = useState([]);
  const { coins, wallets, currencies, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);

  const [wallet, setwallet] = useState("");
  const [wallet2, setwallet2] = useState("");

  const [cryptoToCashData, setcryptoToCashData] = useState({
    amount: null,
    quantity: null,
    crypto: null,
    currency: null,
    email: "",
    country: "",
    city: "",
  });

  const [cashToCryptoData, setcashToCryptoData] = useState({
    currency: null,
    amount: null,
    quantity: null,
    crypto: null,
    fee: "",
    email: "",
    country: "",
    city: "",
    email: "",
  });

  const getPrice = async (coin, currency) => {
    if (!cryptoToCashData.currency) return;
    if (!cryptoToCashData.crypto) return;
    if (!cryptoToCashData.quantity) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
    );
    const _coin = data[coin];
    const _price = parseInt(_coin[currency.toLowerCase()]);
    const price =
      _price *
      parseFloat(cryptoToCashData.quantity) *
      setting.cryptoToCashCharge *
      0.01;

    setcryptoToCashData({
      ...cryptoToCashData,
      amount: price,
    });
  };

  const getPrice2 = async (coin, currency) => {
    if (!cashToCryptoData.currency) return;
    if (!cashToCryptoData.crypto) return;
    if (!cashToCryptoData.amount) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency},usd`
    );
    const _coin = data[coin];
    const _price = parseInt(_coin[currency.toLowerCase()]);
    const price =
      (cashToCryptoData.amount / _price) * setting.cashToCryptoCharge * 0.01;
    const fee = price * parseInt(_coin["usd"]) * setting.buyFee * 0.01;

    // (Receive quantity *price in usdt of that coin) *1%

    setcashToCryptoData({
      ...cashToCryptoData,
      quantity: price,
      fee: fee,
    });
  };

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == cryptoToCashData?.crypto?._id)
    );
    getPrice(cryptoToCashData.crypto?.id, cryptoToCashData.currency?.currency);
  }, [
    cryptoToCashData.crypto,
    cryptoToCashData.currency,
    cryptoToCashData.quantity,
  ]);

  useEffect(() => {
    setwallet2(
      wallets.find((wal) => wal.coin?._id == cashToCryptoData?.crypto?._id)
    );
    getPrice2(
      cashToCryptoData.crypto?.id,
      cashToCryptoData.currency?.currency.toLowerCase()
    );
  }, [
    cashToCryptoData.crypto,
    cashToCryptoData.currency,
    cashToCryptoData.amount,
  ]);

  const submitCryptoToCash = (e) => {
    e.preventDefault();
    console.log(cryptoToCashData);
    if (!cryptoToCashData.email) {
      return toast.warn("Email Is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark  Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cryptoToCashData.email);
    form.set("amount", cryptoToCashData.amount);
    form.set("quantity", cryptoToCashData.quantity);
    form.set("country", cryptoToCashData.country);
    form.set("city", cryptoToCashData.city);
    form.set("coin", cryptoToCashData.crypto?._id);
    form.set("currency", cryptoToCashData.currency?._id);
    form.set("screenShot", screenShot);

    dispatch(applyCryptoToCash(form));

    setcryptoToCashData({
      amount: null,
      quantity: null,
      crypto: null,
      currency: null,
      email: "",
      country: "",
      city: "",
    });
  };

  const submitCashToCrypto = (e) => {
    e.preventDefault();
    if (!screenShot2) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark the Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cashToCryptoData.email);
    form.set("amount", cashToCryptoData.amount);
    form.set("quantity", cashToCryptoData.quantity);
    form.set("country", cashToCryptoData.country);
    form.set("city", cashToCryptoData.city);
    form.set("coin", cashToCryptoData.crypto?._id);
    form.set("currency", cashToCryptoData.currency?._id);
    form.set("usdtFee", cashToCryptoData.fee);
    form.set("screenShot", screenShot2);

    dispatch(applyCashToCrypto(form));

    setcashToCryptoData({
      amount: null,
      quantity: null,
      crypto: null,
      currency: null,
      email: "",
      country: "",
      city: "",
    });
  };

  const fileChange = (e) => {
    
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const fileChange2 = (e) => {
    const file = e.target.files[0];
    const mb = 1024 * 1023;
    if (file.size > mb) {
      return toast.warn("File is above 1 Mb,Compress and try again");
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenShot2(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const addContentHandler = () => {
    if (!cryptoToCashData.crypto) {
      return toast.warn("Please select coin");
    }
    if (!cryptoToCashData.quantity) {
      return toast.warn("Please add an quantity");
    }
    if (!cryptoToCashData.currency) {
      return toast.warn("Please select currency");
    }

    setAddContent(true);
  };
  const addContent2Handler = () => {
    if (!cashToCryptoData.currency) {
      return toast.warn("Please select currency");
    }
    if (!cashToCryptoData.amount) {
      return toast.warn("Please add an amount");
    }
    if (!cashToCryptoData.crypto) {
      return toast.warn("Please select coin");
    }

    setAddContent2(true);
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (checked == "crypto") {
      if (!cryptoToCashData.email) {
        return toast.warn("Enter your email");
      }
      dispatch(otpSend({ email: cryptoToCashData.email }));
    } else if (checked == "cash") {
      if (!cashToCryptoData.email) {
        return toast.warn("Enter your email");
      }
      dispatch(otpSend({ email: cashToCryptoData.email }));
    }
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (checked == "crypto") {
      if (!cryptoToCashData.email) {
        return toast.error("Email is Required");
      }
      dispatch(otpVerify({ email: cryptoToCashData.email, otp }));
    } else if (checked == "cash") {
      if (!cashToCryptoData.email) {
        return toast.error("Email is Required");
      }
      dispatch(otpVerify({ email: cashToCryptoData.email, otp }));
    }
  };

  useEffect(() => {
    if (checked == "crypto") {
      if (cryptoToCashData?.currency?.currency) {
        setcountries(
          currencies.filter(
            (currency) =>
              currency.currency == cryptoToCashData.currency.currency
          )
        );
      }
    }
    if (checked == "cash") {
      if (cashToCryptoData?.currency?.currency) {
        setcountries(
          currencies.filter(
            (currency) =>
              currency.currency == cashToCryptoData.currency?.currency
          )
        );
      }
    }
  }, [cryptoToCashData.currency, cashToCryptoData.currency]);

  return (
    <Layout  title="Crypto to Cash" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <div className="row gx-4 gy-5">
                    <div className="col-12 col-md-6">
                      <div className="row gy-4">
                        <div className="col-12">
                          <h2 className="mb-3 display-6 text-white fw500 d-flex align-items-center gap-3">
                            <Checkbox
                              onClick={() => setchecked("crypto")}
                              active={checked === "crypto"}
                            />
                            Sell Crypto
                          </h2>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" /> Cash out your
                            Crypto with our Dealers
                          </h5>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" /> Your Crypto
                            is secure with us till you get Cash
                          </h5>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" /> Our Dealers
                            are bonded with Crypto deposits
                          </h5>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" /> Most Secure
                            P2D(Person to Dealer) transactions
                          </h5>
                        </div>

                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Crypto"
                          >
                            Crypto
                          </label>
                          <Select
                            disabled={checked !== "crypto"}
                            activeOption={cryptoToCashData.crypto?.name}
                            options={coins}
                            property2="code"
                            img="253"
                            dataChange={(item) => {
                              setcryptoToCashData({
                                ...cryptoToCashData,
                                crypto: item,
                              });
                              // setwallet(
                              //   wallets.find((w) => item._id === w.coin)
                              // );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Amount"
                          >
                            Quantity
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Loan Amount"
                            id="Loan Amount"
                            className="inp_control"
                            placeholder="Quantity"
                            disabled={checked !== "crypto"}
                            value={cryptoToCashData.quantity}
                            onChange={(e) =>
                              setcryptoToCashData({
                                ...cryptoToCashData,
                                quantity: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Currency"
                          >
                            Currency
                          </label>
                          <Select
                            disabled={checked !== "crypto"}
                            activeOption={cryptoToCashData?.currency?.currency}
                            options={currencies}
                            property2="currency"
                            property="country"
                            dataChange={(item) =>
                              setcryptoToCashData({
                                ...cryptoToCashData,
                                currency: item,
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Amount"
                          >
                            Amount
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Loan Amount"
                            id="Loan Amount"
                            className="inp_control"
                            placeholder="Amount.."
                            value={cryptoToCashData.amount}
                            disabled={true}
                          />
                        </div>
                        <div className="col-12">
                          <button
                            disabled={checked !== "crypto"}
                            onClick={addContentHandler}
                            className="w-100 form_submit_btn2 text-white"
                          >
                            Continue
                          </button>
                        </div>

                        <h5 className="text-white">
                          {" "}
                          *For assistance or enquiries please contact
                          support@99krypto.com with your email and transaction
                          ID
                        </h5>
                        {
                          addContent &&   <div className="row gy-4">
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Loan Amount"
                            >
                              Your Email
                            </label>
                            <br />
                            <input
                              type="text"
                              name="email"
                              id="Loan Amount"
                              className="inp_control"
                              value={cryptoToCashData.email}
                              placeholder="Enter Your Email.."
                              disabled={otpSent}
                              onChange={(e) =>
                                setcryptoToCashData({
                                  ...cryptoToCashData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Recieving Country
                            </label>
                            <Select
                              activeOption={cryptoToCashData.country}
                              options={countries}
                              property="country"
                              dataChange={(item) =>
                                setcryptoToCashData({
                                  ...cryptoToCashData,
                                  country: item.country,
                                })
                              }
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Loan Amount"
                            >
                              Receiving City(Optional)
                            </label>
                            <br />
                            <input
                              type="text"
                              name="Loan Amount"
                              id="Loan Amount"
                              className="inp_control"
                              placeholder="City Name.."
                              value={cryptoToCashData.city}
                              onChange={(e) =>
                                setcryptoToCashData({
                                  ...cryptoToCashData,
                                  city: e.target.value,
                                })
                              }
                            />
                          </div>
                          {isCopied && (
                            <p className="copyText">Address copied</p>
                          )}

                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Send Crypto to"
                            >
                              Send Crypto to
                            </label>
                            <input
                              type="text"
                              name="Loan Amount"
                              id="Loan Amount"
                              className="inp_control"
                              style={{ fontSize: "14px" }}
                              value={wallet?.address}
                              disabled
                            />
                            <CopyToClipboard
                              text={wallet?.address}
                              onCopy={() => setisCopied(true)}
                            >
                              <button className="copyButton">Copy</button>
                            </CopyToClipboard>
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Screen shot of Transfer
                            </label>
                            <Upload onChange={fileChange} src={screenShot}>
                              <div className="d-none d-md-block">
                                Drag & Drop your Files Here
                              </div>
                              <div className="d-block d-md-none">Attach</div>
                            </Upload>
                          </div>
                          {otpSent && !otpVerified && (
                            <OtpBox
                              value={otp}
                              onChange={(value) => setotp(value)}
                              onClick={verifyOtp}
                            />
                          )}

                          <div className="col-12 d-flex align-items-center gap-3">
                            <Checkbox
                              onClick={() => setisChecked(!isChecked)}
                              active={isChecked}
                            />
                            <span className="fw600 text-white f18">
                              Terms & Conditions
                            </span>
                          </div>
                          <div className="col-12">
                            {!otpSent ? (
                              <button
                                className="w-100 form_submit_btn2 text-white"
                                onClick={sendOtp}
                              >
                                Send OTP
                              </button>
                            ) : (
                              <button
                                className="w-100 form_submit_btn2 text-white"
                                onClick={submitCryptoToCash}
                              >
                                Submit
                              </button>
                            )}
                            {otpVerified}
                          </div>
                        </div>
                        }
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row gy-4">
                        <div className="col-12">
                          <h2 className="mb-3 display-6 text-white fw500 d-flex align-items-center gap-3">
                            <Checkbox
                              onClick={() => setchecked("cash")}
                              active={checked === "cash"}
                            />
                            Buy Crypto
                          </h2>
                          <h5 className="text-white">
                            {" "}
                            <BsCheck2 size={30} className="m-1" />
                            Buy your Crypto with Cash from our Dealers{" "}
                          </h5>
                          <h5 className="text-white">
                            {" "}
                            <BsCheck2 size={30} className="m-1" /> Secure
                            Transactions, Dealers are bonded with Crypto
                            deposits for your saftey
                          </h5>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" />
                            Fast and Secure P2D(Person to Dealer) transactions{" "}
                          </h5>
                          <h5 className="text-white">
                            <BsCheck2 size={30} className="m-1" /> Pay fee in
                            USDT/USDC and get dealer details to your Email
                          </h5>
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Currency"
                          >
                            Currency
                          </label>
                          <br />
                          <Select
                            disabled={checked !== "cash"}
                            activeOption={cashToCryptoData?.currency?.currency}
                            options={currencies}
                            property2="currency"
                            property="country"
                            dataChange={(item) =>
                              setcashToCryptoData({
                                ...cashToCryptoData,
                                currency: item,
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Amount"
                          >
                            Amount
                          </label>
                          <br />
                          <input
                            disabled={checked !== "cash"}
                            type="text"
                            name="Currency"
                            id="Currency"
                            className="inp_control"
                            placeholder="Amount.."
                            value={cashToCryptoData.amount}
                            onChange={(e) =>
                              setcashToCryptoData({
                                ...cashToCryptoData,
                                amount: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Crypto"
                          >
                            Coin
                          </label>
                          <Select
                            disabled={checked !== "cash"}
                            activeOption={cryptoToCashData.crypto?.name}
                            options={coins}
                            property2="code"
                            img="253"
                            dataChange={(item) => {
                              setcashToCryptoData({
                                ...cashToCryptoData,
                                crypto: item,
                              });
                              // setwallet(
                              //   wallets.find((w) => item._id === w.coin)
                              // );
                            }}
                          />
                        </div>
                        <div className="col-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Amount"
                          >
                            Receiving quantity
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Loan Amount"
                            id="Loan Amount"
                            className="inp_control"
                            value={cashToCryptoData.quantity}
                            disabled
                            onChange={(e) =>
                              setcashToCryptoData({
                                ...cashToCryptoData,
                                quantity: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Fee (USDT/USDC)"
                          >
                            Fee (USDT/USDC)
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Fee (USDT/USDC)"
                            id="Fee (USDT/USDC)"
                            className="inp_control"
                            disabled
                            placeholder="Fee.."
                            value={cashToCryptoData.fee}
                            onChange={(e) =>
                              setcashToCryptoData({
                                ...cashToCryptoData,
                                fee: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <button
                            disabled={checked !== "cash"}
                            onClick={addContent2Handler}
                            className="w-100 form_submit_btn2 text-white"
                          >
                            Continue
                          </button>
                        </div>
                        <h5 className="text-white">
                          *For assistance or enquiries please contact
                          support@99krypto.com with your email and transaction
                          ID
                        </h5>
                        {addContent2 && (
                        <div className="row gy-4">
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Loan Amount"
                            >
                              Your Email
                            </label>
                            <br />
                            <input
                              type="text"
                              name="Loan Amount"
                              id="Loan Amount"
                              className="inp_control"
                              placeholder="Enter your Email..."
                              value={cashToCryptoData.email}
                              disabled={otpSent}
                              onChange={(e) =>
                                setcashToCryptoData({
                                  ...cashToCryptoData,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Recieving Country
                            </label>
                            <Select
                              activeOption={cryptoToCashData.country}
                              options={countries}
                              property="country"
                              dataChange={(item) =>
                                setcashToCryptoData({
                                  ...cashToCryptoData,
                                  country: item.country,
                                })
                              }
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Recieving City ( Optional )
                            </label>
                            <input
                              type="text"
                              name="Loan Amount"
                              style={{ fontSize: "14px" }}
                              id="Loan Amount"
                              className="inp_control"
                              placeholder="City Name"
                              value={cashToCryptoData?.city}
                              onChange={(e) =>
                                setcashToCryptoData({
                                  ...cashToCryptoData,
                                  city: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Send USDT/USDC to
                            </label>
                            <input
                              type="text"
                              name="Loan Amount"
                              style={{ fontSize: "14px" }}
                              id="Loan Amount"
                              className="inp_control"
                              value={wallet2?.address}
                              disabled
                            />
                          </div>
                          <div className="col-12">
                            <label
                              className="text-white mb-1 fw600"
                              htmlFor="Recieving Country"
                            >
                              Screen shot of Transfer
                            </label>
                            <Upload onChange={fileChange2} src={screenShot2}>
                              <div className="d-none d-md-block">
                                Drag & Drop your Files Here
                              </div>
                              <div className="d-block d-md-none">Attach</div>
                            </Upload>
                          </div>
                          {otpSent && !otpVerified && (
                            <OtpBox
                              value={otp}
                              onChange={(value) => setotp(value)}
                              onClick={verifyOtp}
                            />
                          )}

                          <div className="col-12 d-flex align-items-center gap-3">
                            <Checkbox
                              onClick={() => setisChecked(!isChecked)}
                              active={isChecked}
                            />
                            <span className="fw600 text-white f18">
                              Terms & Conditions
                            </span>
                          </div>
                          <div className="col-12">
                            {!otpSent ? (
                              <button
                                className="w-100 form_submit_btn2 text-white"
                                onClick={sendOtp}
                              >
                                Send OTP
                              </button>
                            ) : (
                              <button
                                className="w-100 form_submit_btn2 text-white"
                                onClick={submitCashToCrypto}
                              >
                                Submit
                              </button>
                            )}
                          </div>
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                  <br />
                  <br />

                  
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <div className="fw500">
                      <h4>SELL:</h4>
                      <p className="mb-2">
                        <span className="color4"> 1 :</span> Fill the form
                        and choose the receiving country to receive the cash,
                        send the crypto to our wallet address mentioned, attach
                        the screen shot and continue with submission.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 2 :</span> You will
                        receive an email in 2 hours with the dealer’s contact
                        information. Subject to confirmation of receivables
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 3 :</span> As it is an
                        in-person transaction, ask dealers ID number to confirm
                        with the details provided to you in your email.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 4 :</span> Receive the
                        cash from the dealer and provide them the Security Code.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 5 :</span>As soon as the
                        Security Code is provided to the dealer, transaction is
                        set to be completed and the crypto is released to the
                        dealer and cannot be reversed back
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 6 :</span> For any queries
                        or help please contact us at support@99krypto.com
                      </p>
                    </div>

                    <h4>BUY</h4>

                    <div className="fw500">
                      <p className="mb-2">
                        <span className="color4"> 1 :</span> Fill the form
                        and choose the country where you would like to do the
                        transaction.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 2 :</span> Please check
                        the amount of USDT or USDC need to be paid for
                        transactional fee
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 3 :</span> Transfer the
                        USDT or USDC fee to the mentioned wallet address, attach
                        the screenshot of it and complete the submission.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 4 :</span> You will
                        receive an email in 2 hours with the dealer’s contact
                        information. Subject to confirmation of receivables
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 5 :</span>As it is an
                        in-person transaction, ask dealers ID number to confirm
                        with the details provided to in your email.
                      </p>
                      <p className="mb-2">
                        <span className="color4"> 6 :</span> Provide the
                        cash to the dealer and check your crypto wallet for the
                        transfer then provide the Security Code to the dealer
                      </p>

                      <p className="mb-2">
                        <span className="color4"> 7 :</span> As soon as the
                        Security Code is provided to the dealer transaction is
                        set to be completed. All our dealers are secured by
                        fixed crypto deposits hence they are risk free and
                        secured transaction is assured.
                      </p>
                    </div>
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CryptoCash;
