import React from 'react'

const Spinner = () => {
  return (
    <>
    <div className='spinner' ></div>
    <h4 className='spinner_title' >Submiting...</h4>
    </>
    
  )
}

export default Spinner