import React from "react";
import { useSelector } from "react-redux";

const CryptoToCashTrack = () => {
  const { track: cryptoToCashData } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Status
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={cryptoToCashData.status}
        />
      </div>
      <div className="col-12 col-md-6">
        <label className="text-white mb-1 fw600" htmlFor="Collateral Required">
         Sell Coin
        </label>
        <br />
        <input
          type="text"
          name="Collateral Required"
          id="Collateral Required"
          className="inp_control"
          value={cryptoToCashData.coin?.name}
          placeholder="Interest rate"
          disabled
        />
      </div>
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
        Sell  Quantity
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Quantity"
          disabled
          value={cryptoToCashData.quantity}
        />
      </div>
    
      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
        Recievable Currency
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Currency.."
          value={cryptoToCashData?.currency?.currency}
          disabled={true}
        />
      </div>
  

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
        Recievable Amount
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Amount.."
          value={cryptoToCashData.amount}
          disabled={true}
        />
      </div>

    

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Country
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name.."
          value={cryptoToCashData.country}
          disabled
        />
      </div>

      <div className="col-6">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
           City
        </label>
        <br />
        <input
          type="text"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name.."
          value={cryptoToCashData.city}
          disabled
        />
      </div>
    </>
  );
};

export default CryptoToCashTrack;
