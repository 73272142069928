import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Select2 from "../components/Select2";
import Checkbox from "../components/Checkbox";
import { Link, Redirect, useHistory } from "react-router-dom";
import Upload from "../components/Upload";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import slugify from "react-slugify";

import { useDispatch } from "react-redux";
import { applyLoan, otpSend, otpVerify } from "../redux/actions/serviceAction";
import axios from "axios";
import { toast } from "react-toastify";
import OtpBox from "../components/OtpBox";
import { BsCheckLg } from "react-icons/bs";

const ApplyLoan = () => {
  const dispatch = useDispatch();
  const {
    currencies,
    timePeriods,
    coins,
    wallets,
    success,
    otpSent,
    otpVerified,
    setting,
  } = useSelector((state) => state.service);
  const history = useHistory();

  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);

  const [coinPrice, setcoinPrice] = useState(0);
  const [collateralCoinPrice, setcollateralCoinPrice] = useState("0");
  const [screenShot, setscreenShot] = useState(null);
  const [decodedImage, setdecodedImage] = useState("");

  const [cryptoWallets, setcryptoWallets] = useState([]);
  const [periods, setperiods] = useState([]);

  const [countries, setcountries] = useState([]);

  const [wallet, setwallet] = useState("");
  const [otp, setotp] = useState("");
  // const [otpSent, setotpSent] = useState(false);
  const [addContent, setAddContent] = useState(false);
  const [loanData, setloanData] = useState({
    amount: 0,
    collateralRequired: "",
    timePeriod: {
      interest: "",
    },
    totalTobePaid: "0",
    bankDetails: "",
    collateralCoin: null,
    currency: null,
    country: "",
    city: "",
  });

  useEffect(() => {
    if (!loanData.collateralCoin) return;
    const _periods = timePeriods?.filter(
      (P) => P.coin._id == loanData.collateralCoin._id && P.for == "loan"
    );

    console.log(_periods);

    setperiods(_periods);
    setloanData({ ...loanData, timePeriod: { interest: "" } });
  }, [loanData.collateralCoin]);

  const calTotal = () => {
    if (!loanData.amount) return;
    if (!loanData.timePeriod?.numberOfMonths) return;

    const P = parseInt(loanData.amount);
    const r = loanData?.timePeriod?.interest * 0.01;
    const t = parseInt(loanData.timePeriod?.numberOfMonths) / 12;
    const total = P * (1 + r * t);

    setloanData({
      ...loanData,
      totalTobePaid: total.toFixed(2),
    });
  };

  const formSubmit = (e) => {
    e.preventDefault();

    if (!loanData.email) {
      return toast.warn("Email is required");
    }
    if (!loanData.country) {
      return toast.warn("Country is required");
    }
    if (!loanData.city) {
      return toast.warn("City is required");
    }
    if (!loanData.bankDetails) {
      return toast.warn("Bank Details is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot Is Required");
    }
    if (!isChecked) {
      return toast.warn("Please Checkmark Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Verify your email..");
    }

    const form = new FormData();
    form.set("email", loanData.email);
    form.set("timePeriod", loanData.timePeriod._id);
    form.set("currency", loanData.currency._id);
    form.set("amount", loanData.amount);
    form.set("collateralRequired", loanData.collateralRequired);

    form.set("totalTobePaid", loanData.totalTobePaid);

    form.set("collateralCoin", loanData.collateralCoin._id);
    form.set("bankDetails", loanData.bankDetails);
    form.set("country", loanData.country);
    form.set("city", loanData.city);
    form.set("screenShot", screenShot);

    dispatch(applyLoan(form));
  };

  const getCoinPrice = async (coin, currency) => {
    if (!currency) return;
    if (!coin) return;
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
    );
    setcoinPrice(res.data);
    const _coin = res.data[loanData?.collateralCoin?.id];
    setcollateralCoinPrice(
      parseFloat(_coin[loanData?.currency?.currency.toLowerCase()])
    );
    const percent = setting.loanCharge * 0.01;
    setloanData({
      ...loanData,
      collateralRequired: loanData.amount / percent / collateralCoinPrice,
    });
  };

  useEffect(() => {
    if (!loanData.amount) return;
    if (collateralCoinPrice == 0) return;
    const percent = setting.loanCharge * 0.01;
    const _required = loanData.amount / percent / collateralCoinPrice;
    if (loanData.timePeriod.interest) {
      calTotal();
    }

    setloanData({
      ...loanData,
      collateralRequired: _required,
    });
  }, [collateralCoinPrice]);

  useEffect(() => {
    calTotal();
  }, [loanData.timePeriod, loanData.amount]);

  const fileChange = (e) => {
    const file = e.target.files[0];
    const mb = 1024 * 1020;
    if (file.size > mb) {
      return toast.warn("File is above 1 Mb,Compress and try again");
    }

    console.log(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {

        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };
 

  useEffect(() => {
    setcryptoWallets(wallets);
  }, [wallets]);
  useEffect(() => {
    if (loanData.currency) {
      const items = currencies.filter(
        (c) => c.currency == loanData.currency?.currency
      );
      setcountries(items);
    }
  }, [loanData.currency]);

  useEffect(() => {
    setwallet(
      cryptoWallets.find(
        (wal) => wal?.coin?._id == loanData.collateralCoin?._id
      )
    );
    getCoinPrice(
      slugify(loanData?.collateralCoin?.id),
      loanData?.currency?.currency.toLowerCase()
    );
  }, [loanData.collateralCoin, loanData.currency, loanData.amount]);

  const applyHandler = () => {
    if (!loanData.amount) {
      return toast.warn("Please add amount");
    }
    if (!loanData?.currency?.currency) {
      return toast.warn("Please select currency");
    }
    if (!loanData?.collateralCoin?.name) {
      return toast.warn("Please select Coin");
    }
    if (!loanData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    setAddContent(true);
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: loanData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: loanData.email, otp }));
  };

  return (
    <Layout title={"Apply for Cash  Loan"}>
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">Cash Loan</h1>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> Cash to your Bank Account in
                    24 to 48 hours{" "}
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> Up to 80% Cash Loan on your
                    Asset Value{" "}
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> No monthly Interest Payments{" "}
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Coin / Currency"
                      >
                        Loan Currency
                      </label>

                      {currencies?.length > 0 && (
                        <Select
                          activeOption={loanData?.currency?.currency}
                          options={currencies}
                          property2="currency"
                          property="country"
                          dataChange={(item) =>
                            setloanData({
                              ...loanData,
                              currency: item,
                            })
                          }
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Loan Amount
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        value={loanData.amount}
                        onChange={(e) =>
                          setloanData({
                            ...loanData,
                            amount: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Coin"
                      >
                        Collateral Coin
                      </label>
                      {coins && (
                        <Select
                          activeOption={loanData.collateralCoin?.name}
                          options={coins}
                          property="name"
                          property2={"code"}
                          img="253"
                          dataChange={(item) => {
                            setloanData({ ...loanData, collateralCoin: item });
                            setwallet(wallets.find((w) => item._id === w.coin));
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Required"
                      >
                        Collateral Required
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Collateral Required"
                        id="Collateral Required"
                        className="inp_control"
                        value={`${
                          loanData?.collateralRequired
                            ? loanData?.collateralRequired
                            : "0"
                        }`}
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Period"
                      >
                        Loan Period
                      </label>
                      {timePeriods && (
                        <Select
                          activeOption={loanData?.timePeriod?.name}
                          options={periods}
                          property="name"
                          dataChange={(item) =>
                            setloanData({ ...loanData, timePeriod: item })
                          }
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Interest % APY"
                      >
                        Interest % APY
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Interest % APY"
                        id="Interest % APY"
                        className="inp_control"
                        value={`${loanData?.timePeriod?.interest} %`}
                        disabled={true}
                      />
                    </div>

                    <div className="col-12 col-md-6 d-flex flex-column">
                      <label className="text-white mb-1 fw600" htmlFor="Coin">
                        Total to be paid back
                      </label>
                      <div className=" d-flex">
                        {/* <button>Total</button> */}
                        <input
                          type="text"
                          name="Coin"
                          id="Coin"
                          className="inp_control"
                          value={`${loanData?.totalTobePaid} ${
                            loanData?.currency?.currency
                              ? loanData?.currency?.currency
                              : ""
                          }`}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6 d-flex flex-column">
                      <label className="text-white mb-1 fw600" htmlFor="Coin">
                        Current Coin Price
                      </label>
                      <div className=" d-flex">
                        <input
                          type="text"
                          name="Coin"
                          id="Coin"
                          className="inp_control"
                          value={`${collateralCoinPrice} ${
                            loanData?.currency?.currency
                              ? loanData?.currency?.currency
                              : ""
                          }`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-5 d-flex justify-content-center">
                      <button
                        onClick={applyHandler}
                        className="form_submit_btn text-white"
                      >
                        Apply
                      </button>
                    </div>
                  </div>

                  {addContent && (
                    <div className="mt-5">
                      <div className="row gy-4">
                        <div className="col-12 ">
                          {isCopied && (
                            <p className="copyText">Address copied</p>
                          )}

                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Send Collateral Coin to"
                          >
                            Send Collateral Coin to
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Send Collateral Coin to"
                            id="Send Collateral Coin to"
                            className="inp_control"
                            value={wallet?.address}
                            placeholder="Wallet address"
                          />
                          <CopyToClipboard
                            text={wallet?.address}
                            onCopy={() => setisCopied(true)}
                          >
                            <button className="copyButton">Copy</button>
                          </CopyToClipboard>
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Screen shot of Transfer"
                          >
                            Country
                          </label>
                          <br />
                          {currencies?.length > 0 && (
                            <Select
                              activeOption={loanData?.currency?.currency}
                              options={countries}
                              property="country"
                              dataChange={(item) =>
                                setloanData({
                                  ...loanData,
                                  country: item.country,
                                })
                              }
                            />
                          )}
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Screen shot of Transfer"
                          >
                            City
                          </label>
                          <br />
                          <input
                            type="text"
                            name="City name"
                            id="Send Collateral Coin to"
                            className="inp_control"
                            value={loanData?.city}
                            placeholder="City Name"
                            onChange={(e) =>
                              setloanData({ ...loanData, city: e.target.value })
                            }
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email Address(Please Enter Carefully)"
                          >
                            Email Address(Please Enter Carefully)
                          </label>
                          <br />
                          <input
                            type="email"
                            name="Email Address(Please Enter Carefully)"
                            id="Email Address(Please Enter Carefully)"
                            className="inp_control"
                            placeholder="Enter your email"
                            value={loanData.email}
                            disabled={otpSent}
                            onChange={(e) =>
                              setloanData({
                                ...loanData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Screen shot of Transfer"
                          >
                            Screen shot of Transfer
                          </label>
                          <br />
                          <Upload onChange={fileChange} src={screenShot}>
                            <div className="d-none d-md-block">
                              Drag & Drop your Files Here
                            </div>
                            <div className="d-block d-md-none">Attach</div>
                          </Upload>
                        </div>
                        <div className="col-12 ">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Receiving Address"
                          >
                            Bank/WU address to Receive Loan Amount
                          </label>
                          <br />
                          <input
                            type="text"
                            placeholder=" Bank/WU address to Receive Loan Amount"
                            name="Loan Receiving Address"
                            id="Loan Receiving Address"
                            className="inp_control"
                            value={loanData.bankDetails}
                            onChange={(e) =>
                              setloanData({
                                ...loanData,
                                bankDetails: e.target.value,
                              })
                            }
                          />
                        </div>
                        {otpSent && !otpVerified && (
                          <OtpBox
                            value={otp}
                            onChange={(value) => setotp(value)}
                            onClick={verifyOtp}
                          />
                        )}

                        <div className="col-12 d-flex align-items-center gap-3">
                          <Checkbox
                            onClick={() => setisChecked(!isChecked)}
                            active={isChecked}
                          />
                          <span className="fw600 text-white f18">
                            Terms & Conditions
                          </span>
                        </div>
                        <div className="col-12 mt-5 d-flex justify-content-center">
                          {otpSent ? (
                            <button
                              className="form_submit_btn text-white"
                              onClick={formSubmit}
                            >
                              Submit Application
                            </button>
                          ) : (
                            <button
                              className="form_submit_btn text-white"
                              onClick={sendOtp}
                            >
                              Send OTP
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                  <h5 className="text-white m-3">
                    {" "}
                    *For Quick loan repayments or part payments Contact
                    Support@99krypto.com with your transaction ID and email
                    address
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>:Fill the form and check
                    the total to be paid back
                    <br />
                    <span className="color4"> 2</span>:Send the Collateral coin
                    to our specified wallet adress
                    <br />
                    <span className="color4"> 3</span>:Take a screenshot of
                    crypto transfer, attach it and submit
                    <br />
                    <span className="color4"> 4</span>:Check your transactional
                    email
                    <br />
                    <span className="color4"> 5</span>: We will transfer the
                    funds to your bank account with in 24 to 48 hours, and send
                    you the transfer details by email
                    <br />
                    <span className="color4"> 6</span>:After you get an email
                    with transfer details, you can contact your bank for any
                    delays
                    <br />
                    <span className="color4"> 7</span>:In some cases we are
                    required to ask you to complete KYC, link will be sent to
                    your email. It just takes 5 mins to fill. Once KYC is
                    completed you will not require to do again as long as you
                    use the same email address.
                    <br />
                    <br />
                    <strong>NOTE</strong>
                    <li>
                      As these are high leveraged loans, it is customers
                      responsibility to check and make sure the collateral value
                      never falls below total loan to be paid back
                    </li>
                    <li>
                      If collateral value approaches +5% to total to be paid
                      back, in that situation we may take a call to sell your
                      asset and repay the loan If any balance left will be
                      transferred to your wallet.
                    </li>
                    <li>
                      Monthly interest payments are not compulsory, how ever you
                      can pay to reduce the risk of liquidation
                    </li>
                    <li>
                      For part payments or interest payments please contact us
                      at support@99krypto.com with your email address and
                      transaction ID.
                    </li>
                    <br />
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyLoan;
