import axios from "axios";
import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Checkbox from "../components/Checkbox";
import HowItWorks from "../components/HowItWorks";
import OtpBox from "../components/OtpBox";
import Select from "../components/Select";
import Upload from "../components/Upload";
import Layout from "../layout";
import {
  applyCryptoSwap,
  otpSend,
  otpVerify,
} from "../redux/actions/serviceAction";
import { BsCheck2 } from "react-icons/bs";

const CryptoSwap = () => {
  const { coins, wallets, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const [wallet, setwallet] = useState("");
  const [screenShot, setscreenShot] = useState(null);
  const [addContent, setaddContent] = useState(false);
  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");
  const dispatch = useDispatch();

  const history = useHistory();

  const [cryptoSwapData, setcryptoSwapData] = useState({
    quantity: null,
    crypto: null,
    receivingCrypto: null,
    receivingQuantity: "",
    receivingAddress: "",
    email: "",
  });

  const getPrice = async (coin, receivingCoin) => {
    if (!cryptoSwapData.crypto) return;
    if (!cryptoSwapData.quantity) return;
    const { data } = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${coin},${receivingCoin}&vs_currencies=usd`
    );
    const _coin = data[coin];
    const _receivingCoin = data[receivingCoin];
    const _receivingCoinPrice = parseInt(_receivingCoin["usd"]);
    const _price = parseInt(_coin["usd"]);
    const qtyprice = _price * cryptoSwapData.quantity;
    const price = (qtyprice / _receivingCoinPrice) * setting.swapCharge * 0.01;

    setcryptoSwapData({
      ...cryptoSwapData,
      receivingQuantity: price,
    });
  };

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == cryptoSwapData?.crypto?._id)
    );
    getPrice(cryptoSwapData.crypto?.id, cryptoSwapData.receivingCrypto?.id);
  }, [
    cryptoSwapData.crypto,
    cryptoSwapData.quantity,
    cryptoSwapData.receivingCrypto,
  ]);

  const submitCryptoSwap = (e) => {
    e.preventDefault();
    console.log(cryptoSwapData);
    if (!cryptoSwapData.email) {
      return toast.warn("Email Is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }

    if (!otpVerified) {
      return toast.warn("Please Verify Your Email");
    }

    const form = new FormData();
    form.set("email", cryptoSwapData.email);
    form.set("quantity", cryptoSwapData.quantity);
    form.set("crypto", cryptoSwapData.crypto?._id);
    form.set("receivingCrypto", cryptoSwapData.receivingCrypto?._id);
    form.set("receivingQuantity", cryptoSwapData.receivingQuantity);
    form.set("receivingAddress", cryptoSwapData.receivingAddress);
    form.set("screenShot", screenShot);

    dispatch(applyCryptoSwap(form));

    setcryptoSwapData({
      quantity: null,
      crypto: null,
      receivingCrypto: null,
      receivingQuantity: "",
      receivingAddress: "",
      email: "",
    });
  };

  const addContentHandler = () => {
    setaddContent(true);
  };

  const sendOtp = (e) => {
    e.preventDefault();

    if (!cryptoSwapData.email) {
      return toast.warn("Enter your email");
    }
    dispatch(otpSend({ email: cryptoSwapData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (!cryptoSwapData.email) {
      return toast.error("Email is Required");
    }
    dispatch(otpVerify({ email: cryptoSwapData.email, otp }));
  };

  const fileChange = (e) => {
    
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      console.log(success);
      return history.push("/submit-thankyou");
    }
  }, [success]);

  return (
    <Layout  title="Crypto Swap" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">
                    Crypto Swaps
                  </h1>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={30} className="m-1" /> No Slippage
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={30} className="m-1" />
                    Fast and easy swapping
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={30} className="m-1" />
                    Immediate Transfer to Your wallet*
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-6">
                      <label className="text-white mb-1 fw600" htmlFor="Crypto">
                        Crypto
                      </label>
                      <Select
                        activeOption={cryptoSwapData.crypto?.name}
                        options={coins}
                        property="code"
                        img="253"
                        dataChange={(item) => {
                          setcryptoSwapData({
                            ...cryptoSwapData,
                            crypto: item,
                          });
                          // setwallet(
                          //   wallets.find((w) => item._id === w.coin)
                          // );
                        }}
                      />
                    </div>
                    <div className="col-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Quantity
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="Quantity"
                        value={cryptoSwapData.quantity}
                        onChange={(e) =>
                          setcryptoSwapData({
                            ...cryptoSwapData,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Currency"
                      >
                        Receiving Crypto
                      </label>
                      <Select
                        activeOption={cryptoSwapData?.receivingCrypto?.name}
                        options={coins}
                        property="code"
                        img={"13"}
                        dataChange={(item) =>
                          setcryptoSwapData({
                            ...cryptoSwapData,
                            receivingCrypto: item,
                          })
                        }
                      />
                    </div>
                    <div className="col-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Receiving Quantity
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="Receiving Quantity.."
                        value={cryptoSwapData.receivingQuantity}
                        disabled={true}
                      />
                    </div>

                    <div className="col-12 mt-5 d-flex justify-content-center">
                      <button
                        onClick={addContentHandler}
                        className="form_submit_btn text-white"
                      >
                        Apply
                      </button>
                    </div>
                    <h5 className="text-white m-2">
                      *For assistance or enquiries contact Support@99krypto.com
                      with your transaction ID and email address
                    </h5>
                  </div>

                  {addContent && (
                    <div className="mt-5">
                      <div className="row gy-4">
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Amount"
                          >
                            Your Email
                          </label>
                          <br />
                          <input
                            type="text"
                            name="email"
                            id="Loan Amount"
                            className="inp_control"
                            value={cryptoSwapData.email}
                            placeholder="Enter Your Email.."
                            disabled={otpSent}
                            onChange={(e) =>
                              setcryptoSwapData({
                                ...cryptoSwapData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Recieving Country"
                          >
                            Your wallet Address to receive Crypto
                          </label>
                          <input
                            type="text"
                            name="Loan Amount"
                            id="Loan Amount"
                            className="inp_control"
                            value={cryptoSwapData?.receivingAddress}
                            onChange={(e) =>
                              setcryptoSwapData({
                                ...cryptoSwapData,
                                receivingAddress: e.target.value,
                              })
                            }
                          />
                        </div>

                        {isCopied && <p className="copyText">Address copied</p>}

                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Send Crypto to"
                          >
                            Send Crypto to
                          </label>
                          <input
                            type="text"
                            name="Loan Amount"
                            id="Loan Amount"
                            className="inp_control"
                            value={wallet?.address}
                            disabled
                          />
                          <CopyToClipboard
                            text={wallet?.address}
                            onCopy={() => setisCopied(true)}
                          >
                            <button className="copyButton">Copy</button>
                          </CopyToClipboard>
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Recieving Country"
                          >
                            Screen shot of Transfer
                          </label>
                          <Upload onChange={fileChange} src={screenShot}>
                            <div className="d-none d-md-block">
                              Drag & Drop your Files Here
                            </div>
                            <div className="d-block d-md-none">Attach</div>
                          </Upload>
                        </div>
                        {otpSent && !otpVerified && (
                          <OtpBox
                            value={otp}
                            onChange={(value) => setotp(value)}
                            onClick={verifyOtp}
                          />
                        )}

                        <div className="col-12 d-flex align-items-center gap-3">
                          <Checkbox
                            onClick={() => setisChecked(!isChecked)}
                            active={isChecked}
                          />
                          <span className="fw600 text-white f18">
                            Terms & Conditions
                          </span>
                        </div>
                        <div className="col-12">
                          {!otpSent ? (
                            <button
                              className="w-100 form_submit_btn2 text-white"
                              onClick={sendOtp}
                            >
                              Send OTP
                            </button>
                          ) : (
                            <button
                              className="w-100 form_submit_btn2 text-white"
                              onClick={submitCryptoSwap}
                            >
                              Submit
                            </button>
                          )}
                          {otpVerified}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>:You can swap your
                    crypto to any other coin of your choice
                    <br />
                    <span className="color4"> 2</span>: Fill the form and
                    check the total to be received for swap
                    <br />
                    <span className="color4"> 3</span>: Send your swap coins
                    to our specified wallet adress
                    <br />
                    <span className="color4"> 4</span>: Fill the form and
                    check the total to be received for swap
                    <br />
                    <span className="color4"> 5</span>:Take a screenshot of
                    crypto transfer, attach it and submit
                    <br />
                    <span className="color4"> 6</span>:We will transfer the
                    Crypto to your wallet specified by you , within 2 hours.
                    Subject to confirmations
                    <br />
                    <span className="color4"> 7</span>:In some cases we are
                    required to ask you to complete KYC, in that case, link will
                    be sent to your email. It just takes 5 mins to complete.
                    Once KYC is completed you will not require to do it again as
                    long as you use the same email address
                    <br />
                    <br />
                    <strong>NOTE</strong>

                    <li>No slippage, you get what you see</li>
                    <li>No transaction fee</li>
                    <li>
                      You can always contact us at support@99krypto.com for any
                      questions or help
                    </li>
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CryptoSwap;
