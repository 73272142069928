import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import Password from "../components/Password";
import Layout from "../layout";
import { login } from "../redux/actions/userAction";

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [userData, setuserData] = useState({
    email: "",
    password: "",
  });

  const submitHandler = () => {
    if (!userData.email || !userData.password) {
      return toast.warn("All fields are required");
    }

    dispatch(login(userData.email, userData.password));
  };

  useEffect(() => {
    if (isAuthenticated && !user.submitted) {
      return history.push("/kyc-registration");
    } else if (isAuthenticated && user.kyc_verified) {
      console.log("redirect Profile");
      return history.push("/profile");
    }
  }, [isAuthenticated]);

  return (
    <Layout title="Sign Up">
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="register">
            <h3 className="text-center  mb-3 text-white display-5 fw500">
              Sign In
            </h3>
            <div className="row gy-1">
              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Email  ( Same as provided for FD )"
                >
                  Email
                </label>
                <br />
                <input
                  type="text"
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.email}
                  placeholder="Your email address"
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      email: e.target.value,
                    })
                  }
                />
              </div>

              <div className="col-12">
                <label
                  className="text-white mb-1 fw600"
                  htmlFor="Choose a strong password"
                >
                  Password
                </label>
                <br />
                <Password
                  name="name"
                  id=""
                  className="inp_control"
                  value={userData.password}
                  autoComplete={false}
                  onChange={(e) =>
                    setuserData({
                      ...userData,
                      password: e.target.value,
                    })
                  }
                />
              </div>

              <button
                className="w-100 form_submit_btn2 text-white"
                onClick={submitHandler}
              >
                Login
              </button>
              <p className="text-center text-white">
                Don't have an account ?{" "}
                <Link to="/sign-up">
                  <a className="fw600 color4 m-1">Sign Up</a>{" "}
                </Link>{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Login;
