import React, { useState } from "react";

const SelectCardType = ({ imageData, setimageData }) => {
  return (
    <div>
      <div className="select-card">
        <div className="card" onClick={() => setimageData("nid")}>
          National ID Card
        </div>
        <div className="card" onClick={() => setimageData("passport")}>
          Passport
        </div>
        <div className="card" onClick={() => setimageData("driving_licence")}>
          Driving Licence
        </div>
      </div>
    </div>
  );
};

export default SelectCardType;
