import React from "react";
import ReactModal from "react-modal";
import { AiOutlineClose } from "react-icons/ai";
import "react-responsive-modal/styles.css";
import { Modal as ResponsiveModal } from "react-responsive-modal";


const DetailsModal = ({ modalOpen, closeModal, children }) => {
  return (
    <ResponsiveModal
      isOpen={modalOpen}
      onClose={closeModal}
      open={modalOpen}
      center
    >
      <div
        className=" 
     modalContent"
      >
        {/* <AiOutlineClose size={28} className="modalClose" onClick={closeModal} /> */}
        {children}
      </div>
    </ResponsiveModal>
  );
};

export default DetailsModal;
