import React, { useState } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Checkbox from "../components/Checkbox";
import Password from "../components/Password";
import { useDispatch, useSelector } from "react-redux";
import { login, registerDealer } from "../redux/actions/userAction";
import Upload from "../components/Upload";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { BsCheck2 } from "react-icons/bs";
import { otpSend, otpVerify } from "../redux/actions/serviceAction";
import OtpBox from "../components/OtpBox";
import Select from "../components/Select";

const Dealer = () => {
  const dispatch = useDispatch();
  const [active, setactive] = useState("");

  const [screenshot, setscreenshot] = useState(null);
  const { isAuthenticated } = useSelector((state) => state.user);
  const { otpSent, otpVerified, currencies } = useSelector(
    (state) => state.service
  );
  const history = useHistory();
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");

  const [otp, setotp] = useState("");

  const [photoId, setphotoId] = useState(null);

  const [registerData, setregisterData] = useState({
    name: "",
    email: "",
    password: "",
    country: "",
    city: "",
    phoneNo: "",
    transactionId: "",
  });

  const registerSubmit = (e) => {
    e.preventDefault();
    if (!screenshot) {
      return toast.warn(" Screenshot is required");
    }
    if (active !== "register") {
      return toast.warn("Please Check Mark Register");
    }
    if (!registerData.country) {
      return toast.warn("Please Select Your Country");
    }
    if (!screenshot) {
      return toast.warn("Please insert your ID/PASSPORT's Photo");
    }
    if (!otpVerified) {
      return toast.warn("Please Verify Your Email First");
    }

    const form = new FormData();
    form.set("name", registerData.name);

    form.set("email", registerData.email);
    form.set("password", registerData.password);
    form.set("country", registerData.country);
    form.set("city", registerData.city);
    form.set("transactionId", registerData.transactionId);
    form.set("phoneNo", registerData.phoneNo);
    form.set("screenshot", screenshot);
    form.set("photoId", photoId);

    // form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(registerDealer(form));
  };

  const loginSubmit = (e) => {
    e.preventDefault();
    if (active !== "login") {
      return toast.warn("Please Check Mark Login");
    }

    dispatch(login(email, password));
    setemail("");
    setpassword("");
  };

  const fileChange = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setphotoId(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (isAuthenticated) {
      return history.push("/dashboard");
    }
  }, [isAuthenticated]);

  const sendOtp = (e) => {
    e.preventDefault();

    if (!registerData.email) {
      return toast.warn("Enter your email");
    }
    dispatch(otpSend({ email: registerData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    if (!registerData.email) {
      return toast.error("Email is Required");
    }
    dispatch(otpVerify({ email: registerData.email, otp }));
  };

  return (
    <Layout  title="Dealer Registration" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-md-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">
                    Be a Dealer
                  </h1>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    Be a dealer by opening a deposit account as security, earn
                    up to 5% on each transaction.
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    You can accept orders up to 5 times worth of your crypto
                    deposits.
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    Accept cash and transfer crypto to your customers.
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    Pay cash to your customers, we transfer you the crypto to
                    your wallet.
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    Orders are emailed to your mailbox or mobile with a secured
                    transaction code.
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheck2 size={26} className="mr-1" />
                    Crypto security deposit accounts for dealers can be
                    cancelled any time with an email request. **
                  </h4>

                  <br />

                  <div className="row gy-5">
                    <div className="col-12 col-md-6">
                      <div className="row gy-4">
                        <div className="col-12 d-flex align-items-center gap-3">
                          <Checkbox
                            active={active === "register"}
                            onClick={() => setactive("register")}
                          />
                          <h2 className="mb-0 fw600 text-white">
                            Create Account
                          </h2>
                        </div>

                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email  ( Same as provided for FD )"
                          >
                            Full Name
                          </label>
                          <br />
                          <input
                            type="text"
                            name="name"
                            id=""
                            className="inp_control"
                            value={registerData.name}
                            autoComplete={false}
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                name: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email  ( Same as provided for FD )"
                          >
                            Email ( Same as provided for FD )
                          </label>
                          <br />
                          <input
                            autoComplete={false}
                            type="text"
                            name="Email  ( Same as provided for FD )"
                            id="Email  ( Same as provided for FD )"
                            className="inp_control"
                            value={registerData.email}
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email  ( Same as provided for FD )"
                          >
                            Password ( Alpha, Numerical and Symbol )
                          </label>
                          <br />
                          <Password
                            value={registerData?.password}
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                password: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Fixed Deposit No"
                          >
                            Fixed Deposit No
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Fixed Deposit No"
                            id="Fixed Deposit No"
                            className="inp_control"
                            placeholder="Transaction ID of Fixed Deposit"
                            value={registerData.transactionId}
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                transactionId: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Fixed Deposit No"
                          >
                            Country
                          </label>
                          <br />

                          <Select
                            activeOption={currencies}
                            options={currencies}
                            property="country"
                            dataChange={(item) =>
                              setregisterData({
                                ...registerData,
                                country: item.country,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Fixed Deposit No"
                          >
                            City
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Fixed Deposit No"
                            id="Fixed Deposit No"
                            className="inp_control"
                            value={registerData.city}
                            placeholder="City Name"
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                city: e.target.value,
                              })
                            }
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Fixed Deposit No"
                          >
                            Phone Number
                          </label>
                          <br />
                          <input
                            type="phone"
                            name="Fixed Deposit No"
                            id="Fixed Deposit No"
                            className="inp_control"
                            placeholder="Phone Number"
                            value={registerData.phoneNo}
                            onChange={(e) =>
                              setregisterData({
                                ...registerData,
                                phoneNo: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12 ">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Agreement If any"
                          >
                            Screenshot of ID/Passport
                          </label>
                          <br />
                          <Upload onChange={fileChange} src={photoId}>
                            <div className="d-none d-md-block">
                              Drag & Drop your Files Here
                            </div>
                            <div className="d-block d-md-none">Attach</div>
                          </Upload>
                        </div>
                        {otpSent && !otpVerified && (
                          <OtpBox
                            value={otp}
                            onChange={(value) => setotp(value)}
                            onClick={verifyOtp}
                          />
                        )}
                        <div className="col-12 d-flex justify-content-center">
                          {!otpSent ? (
                            <button
                              className="w-100 form_submit_btn2 text-white"
                              onClick={sendOtp}
                            >
                              Send OTP
                            </button>
                          ) : (
                            <button
                              className="w-100 form_submit_btn2 text-white"
                              onClick={registerSubmit}
                            >
                              Submit
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="row gy-4">
                        <div className="col-12 d-flex align-items-center gap-3">
                          <Checkbox
                            active={active === "login"}
                            onClick={() => setactive("login")}
                          />

                          <h2 className="mb-0 fw600 text-white">Login</h2>
                        </div>

                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email"
                          >
                            Email
                          </label>
                          <br />
                          <input
                            type="email"
                            name="Email"
                            id="Email"
                            className="inp_control"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setemail(e.target.value)}
                          />
                        </div>
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Password"
                          >
                            Password
                          </label>
                          <br />
                          <Password
                            placeholder="Enter Password"
                            value={password}
                            onChange={(e) => setpassword(e.target.value)}
                          />
                        </div>
                        <p>
                          <Link to="/forget-password">
                            <span> Forgot Password</span>
                          </Link>
                        </p>

                        <div className="col-12 d-flex justify-content-center">
                          <button
                            className="form_submit_btn2 w-100 text-white"
                            onClick={loginSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>: New dealers should first
                    open a fixed term deposit to subscribe as a Dealer
                    <br />
                    <span className="color4"> 2</span>:Your fixed deposit will
                    act as a security deposit and can be leveraged 5 times to
                    accept orders
                    <br />
                    <span className="color4">Ex</span>:
                    <ul className="mb-0 mt-4">
                      <li>
                        Let’s say you have deposited a crypto coin which is
                        worth $1000 today, So you are allowed to accept orders
                        up to $5000
                      </li>
                    </ul>
                    <br />
                    <span className="color4"> 3</span>: For each transaction you
                    can earn up to 2% to 5%.
                    <br />
                    <br />
                    <span className="color4"> 4</span>:Dealers should do the
                    transaction for the invoiced amount. Collecting extra fee or
                    transacting for higher quantity or higher amount would cause
                    to block the account and freeze the security deposit
                    <br />
                    <span className="color4"> 5</span>: Please accept the orders
                    when you are sure to deliver. If any failure to do so will
                    attract a penalty of 5%of the transaction amount or quantity
                    <br />
                    <span className="color4"> 6</span>: Collect the Security
                    Code from the customer and submit it in your order panel to
                    complete the transaction
                    <br />
                    <span className="color4"> 7</span>: For buy orders, dealers
                    should accept cash and transfer crypto. After completion of
                    transaction, dealers are required to collect the Security
                    Code from customers and submit that in the order profile to
                    complete the transaction
                    <br />
                    <span className="color4"> 8</span>: For sell orders dealers
                    should pay cash and collect OTP from the customer. After
                    successful transaction, we will transfer the Crypto to your
                    wallet in 2 hours.
                    <br />
                    <span className="color4"> 9</span>: For any support please
                    contact us at support@99krypto.com
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dealer;
