import React from "react";
import { useSelector } from "react-redux";

const PaymentTrack = () => {
  const { track: paymentData } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-12 ">
        <div className="apply_loan_right_container p-4">
          <h1 className="mb-0 text-white display-5 fw500">Payment Service</h1>
          <br />
          <div className="row gy-4">
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Sender Email">
                Status
              </label>
              <br />
              <input
                type="text"
                name="Sender Email"
                id="Sender Email"
                className="inp_control"
                placeholder="Enter sender's email address"
                value={paymentData.status}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Period">
                Sent Coin
              </label>
              <input
                type="text"
                name="To be paid"
                id="To be paid"
                className="inp_control"
                value={paymentData.coin?.name}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Amount">
                Sent Quantity
              </label>
              <br />
              <input
                type="text"
                name="Amount"
                id="Amount"
                className="inp_control"
                placeholder="Enter amount"
                value={paymentData.totalTobePaid}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Amount">
                Payable Currency
              </label>
              <br />
              <input
                type="text"
                name="Amount"
                id="Amount"
                className="inp_control"
                placeholder="Enter amount"
                value={paymentData.currency?.currency}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Amount">
                Payable Amount
              </label>
              <br />
              <input
                type="text"
                name="Amount"
                id="Amount"
                className="inp_control"
                placeholder="Enter amount"
                value={paymentData.amount}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentTrack;
