import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/symbol.png";

const Header = () => {
  return (
    <div className="header_container py-2">
      <div className="page_container">
        <div className="container-fluid">
          <Link to="/" className="text-decoration-none">
            <div className="d-flex logo  align-items-center">
              <img className="me-2 " src={logo} alt="" />
              <h2>
                99 krypto <br /> <p>Crypto cash payments</p>{" "}
              </h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
