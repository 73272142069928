import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  CLEAR_ERRORS,
  kycConstants,
  userData,
} from "../constants/userConstants";
import axios from "../utils/backend_api";
import { backend_api } from "../utils/backend_api";
import { toast } from "react-toastify";
import { otpConstants } from "../constants/serviceConstants";

// Login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/login`,
      { email, password },
      config
    );

    localStorage.setItem("token", data.token);
    localStorage.setItem("user", JSON.stringify(data.user));

    toast.success("Login Successful");

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    window.location.reload();
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({ type: LOGIN_FAIL, payload: error?.response?.data.message });
  }
};

// Register
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${backend_api}/api/v1/register`,
      userData,
      config
    );

    localStorage.setItem("token", data.token);

    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });

    window.location.pathname = "/kyc-registration";
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const registerDealer = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${backend_api}/api/v1/dealer_register`,
      userData,
      config
    );

    localStorage.setItem("token", data.token);

    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });

    toast.success("Registration Successful ");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

export const kycGeneralInfo = (userData) => async (dispatch) => {
  try {
    dispatch({ type: kycConstants.KYC_REGISTRATION_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${backend_api}/api/v1/kyc`,
      userData,
      config
    );

    dispatch({
      type: kycConstants.KYC_REGISTRATION_SUCCESS,
      payload: data.user,
    });
    dispatch({ type: otpConstants.SEND_OTP_SUCCEESS, payload: data.user });

    toast.success("Successfully Submitted");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: kycConstants.KYC_REGISTRATION_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const kycRegister = (userData) => async (dispatch) => {
  try {
    dispatch({ type: kycConstants.KYC_REGISTRATION_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(
      `${backend_api}/api/v1/kyc`,
      userData,
      config
    );

    localStorage.setItem("token", data.token);

    dispatch({
      type: kycConstants.KYC_REGISTRATION_SUCCESS,
      payload: data.user,
    });
    dispatch({ type: otpConstants.SEND_OTP_SUCCEESS, payload: data.user });

    toast.success("Successfully Submitted");

    window.location.pathname = "/profile";
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: kycConstants.KYC_REGISTRATION_FAILURE,
      payload: error.response.data.message,
    });
  }
};

export const kycOtpVerify = (loanData) => async (dispatch) => {
  try {
    dispatch({ type: otpConstants.OTP_VERIFY_REQUEST });
    const config = {
      headers: { "Content-Type": "application/json" },
      credentials: "include",
    };

    const { data } = await axios.post(
      `${backend_api}/api/v1/kyc_otp_verify`,

      loanData,

      config
    );

    dispatch({
      type: otpConstants.OTP_VERIFY_SUCCESS,
      payload: data,
    });
    toast.success("OTP Verified Successfully");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: otpConstants.OTP_VERIFY_FAILURE,
      payload: error?.response?.data.message,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/me`);

    dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  try {
    await axios.get(`${backend_api}/api/v1/logout`);

    localStorage.removeItem("token");

    window.localStorage.removeItem("token");

    toast.success("Logout Successful");

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    toast.error("Something went wrong");
    dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
  }
};

// Update Profile
export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.put(
      `${backend_api}/api/v1/me/update`,
      userData,
      config
    );

    localStorage.setItem("user", JSON.stringify(data.user));

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.user });

    toast.success("Successfully Updated");
  } catch (error) {
    toast.error(error?.response?.data.message);

    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Password
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `${backend_api}/api/v1/password/update`,
      passwords,
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(
      `${backend_api}/api/v1/password/forgot`,
      email,
      config
    );

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
    toast.success("Please check your email");
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `${backend_api}/api/v1/password/reset/${token}`,
      passwords,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });

    toast.success("Password Updated Successfully ! Please Login now");

    window.location.pathname = "/dealer";
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`${backend_api}/api/v1/admin/users`);

    dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
  }
};

// get  User Details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const { data } = await axios.get(`${backend_api}/api/v1/admin/user/${id}`);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FAIL, payload: error.response.data.message });
  }
};

// Update User
export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `${backend_api}/api/v1/admin/user/${id}`,
      userData,
      config
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(
      `${backend_api}/api/v1/admin/user/${id}`
    );

    dispatch({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};

export const getUserData = (id) => async (dispatch) => {
  try {
    dispatch({ type: userData.GET_USERDATA_REQUEST });

    const { data } = await axios.get(`${backend_api}/api/v1/user/${id}`);
    console.log(data);

    dispatch({ type: userData.GET_USERDATA_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({
      type: userData.GET_USERDATA_FAILURE,
      payload: error.response.data.message,
    });
  }
};
