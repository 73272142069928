import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  loadUser,
  logout,
  updateProfile,
} from "../../redux/actions/userAction";

const Account = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();

  const [userData, setuserData] = useState(null);

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/dealer");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setuserData(user);
  }, [user]);

  const update = () => {
    dispatch(updateProfile({ ...userData, depositId: userData._id }));
  };

  return (
    <>
      <div className="col-12 ">
        <div className="apply_loan_right_container p-4">
          <h1 className="mb-0 text-white display-5 fw500">Your Profile</h1>
          <br />
          <div className="row gy-4">
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Name
              </label>
              <br />
              <input
                type="email"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                placeholder="Your Name"
                disabled
                value={user?.name}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Email
              </label>
              <br />
              <input
                type="email"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                placeholder="Enter quantity"
                value={user?.email}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Phone Number
              </label>
              <input
                type="email"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                placeholder="Enter your email"
                value={userData?.phoneNo}
                onChange={(e) =>
                  setuserData({ ...userData, phoneNo: e.target.value })
                }
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                Country
              </label>
              <br />
              <input
                type="text"
                name="Coin"
                id="Coin"
                className="inp_control"
                disabled={true}
                value={user?.country}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                City
              </label>
              <br />
              <input
                type="text"
                name="Coin"
                id="Coin"
                className="inp_control"
                disabled={true}
                value={user?.city}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                Fixed Deposit No
              </label>
              <br />
              <input
                type="text"
                name="Coin"
                id="Coin"
                className="inp_control"
                placeholder="Transaction ID of Deposit"
                disabled={true}
                value={user?.depositId?._id}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                USER ID
              </label>
              <br />
              <input
                type="text"
                name="Coin"
                id="Coin"
                className="inp_control"
                placeholder="Your USER ID"
                disabled={true}
                value={user?._id}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                Deposit Amount
              </label>
              <br />
              <input
                type="text"
                name="Coin"
                id="Coin"
                className="inp_control"
                placeholder="Your USER ID"
                disabled={true}
                value={user?.depositId?.amount?.toString()}
              />
            </div>

            <div className="col-12 mt-3 d-flex justify-content-center">
              <button onClick={update} className="form_submit_btn text-white">
                Update Profile
              </button>
            </div>

            <div className="col-12 mt-3 d-flex justify-content-center">
              <Link to="/change-password">
                <button className="form_submit_btn text-white p-3">
                  Change Password
                </button>
              </Link>
            </div>

            <div className="col-12 mt-3 d-flex justify-content-center">
              <button
                onClick={() => dispatch(logout())}
                className="form_submit_btn text-white"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Account;
