import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const LoanTrack = ({}) => {
  const { track: loanData } = useSelector((state) => state.service);

  console.log(loanData);

  return (
    <>
      <div className="col-12 col-lg-12">
        <div className="apply_loan_right_container p-4">
          <h1 className="mb-0 text-white display-5 fw500">Loan</h1>
          <br />
          <div className="row gy-4">
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Status
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                value={loanData?.status}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Loan Currency
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                value={loanData?.currency?.currency}
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Loan Amount
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                value={loanData?.amount}
              />
            </div>
            <div className="col-12 col-md-6">
              <label
                className="text-white mb-1 fw600"
                htmlFor="Collateral Required"
              >
                Collateral Coin
              </label>
              <br />
              <input
                type="text"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                value={loanData?.collateralCoin?.name}
                disabled={true}
              />
            </div>

            <div className="col-12 col-md-6">
              <label
                className="text-white mb-1 fw600"
                htmlFor="Collateral Required"
              >
                Collateral Quantity
              </label>
              <br />
              <input
                type="text"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                value={loanData?.collateralRequired}
                disabled={true}
              />
            </div>

            <div className="col-12 col-md-6 d-flex flex-column">
              <label className="text-white mb-1 fw600" htmlFor="Coin">
                Total to be paid back
              </label>
              <div className=" d-flex">
                {/* <button>Total</button> */}
                <input
                  type="text"
                  name="Coin"
                  id="Coin"
                  className="inp_control"
                  value={`${loanData?.totalTobePaid} ${
                    loanData?.currency?.currency
                      ? loanData?.currency?.currency
                      : ""
                  }`}
                  disabled={true}
                />
              </div>
            </div>

            <div className="col-12 col-md-6">
              <label
                className="text-white mb-1 fw600"
                htmlFor="Collateral Required"
              >
                Outstanding Amount
              </label>
              <br />
              <input
                type="text"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                value={loanData?.totalTobePaid - loanData?.partialRepayment}
                disabled={true}
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Loan Start Date
              </label>
              <input
                type="email"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                placeholder="Enter your email"
                value={moment(loanData.loanStartDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Loan End Date
              </label>
              <input
                type="email"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                placeholder="Enter your email"
                value={moment(loanData.loanEndDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LoanTrack;
