import React from "react";
import { useSelector } from "react-redux";

const EscrowTrack = () => {
  const { track: escrowData, setting } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-12 ">
        <div className="apply_loan_right_container p-4">
          <h1 className="mb-0 text-white display-5 fw500">Escrow Service</h1>
          <br />
          <div className="row gy-4">
            <div className="col-12">
              <label className="text-white mb-1 fw600" htmlFor="Party 2">
                Status
              </label>
              <input
                type="text"
                name="Email Address"
                id="Email Address"
                className="inp_control"
                placeholder="Select Party1 "
                value={escrowData.status}
                disabled
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Crypto">
                Escrow Coin
              </label>
              <input
                type="text"
                name="Amount"
                id="Amount"
                className="inp_control"
                value={escrowData.coin.name}
                placeholder="Enater quantity"
                disabled
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Amount">
                Escrow Quantity
              </label>
              <br />
              <input
                type="text"
                name="Amount"
                id="Amount"
                className="inp_control"
                value={escrowData.quantity}
                placeholder="Enater quantity"
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Party 1 Email
              </label>
              <br />
              <input
                type="text"
                name="Email Address"
                id="Email Address"
                className="inp_control"
                placeholder="Email address of party 1"
                value={escrowData.email1}
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Party 2 Email
              </label>
              <br />
              <input
                type="text"
                name="Email Address"
                id="Email Address"
                className="inp_control"
                placeholder="Email address of party 2"
                value={escrowData.email2}
                disabled
              />
            </div>

            <div className="col-12">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
                Notes
              </label>
              <br />
              <input
                type="text"
                name="Email Address"
                id="Email Address"
                className="inp_control"
                placeholder="Email address of party 2"
                value={escrowData.notes}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EscrowTrack;
