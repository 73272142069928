import React, { useState } from "react";
import Password from "../Password";
import Select from "../Select";
import { useDispatch, useSelector } from "react-redux";
import { kycGeneralInfo } from "../../redux/actions/userAction";
import { useEffect } from "react";

const CreateAccount = ({ step, setstep, userData, setuserData }) => {
  const { currencies, otpSent } = useSelector((state) => state.service);
  const {
    firstName,
    lastName,
    birthDate,
    gender,
    country,
    city,
    email,
    phoneNo,
  } = userData;

  const dispatch = useDispatch();

  const submitHandler = () => {
    dispatch(
      kycGeneralInfo({
        _id: userData._id,
        firstName,
        lastName,
        birthDate,
        gender,
        country,
        city,
        email,
        phoneNo,
        sendOtp: true,
      })
    );
  };

  useEffect(() => {
    if (otpSent) {
      setstep(step + 1);
    }
  }, [otpSent]);

  return (
    <>
      <div className="col-12 col-md-6 ">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Date Of Birth
        </label>
        <br />
        <input
          type="date"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="Date of birth "
          value={userData?.birthDate}
          onChange={(e) =>
            setuserData({ ...userData, birthDate: e.target.value })
          }
        />
      </div>
      <div className="col-12 col-md-6 ">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Gender
        </label>
        <br />
        <Select
          property={"gender"}
          options={[
            { id: "1", gender: "male" },
            { id: "2", gender: "female" },
            { id: "3", gender: "custom" },
          ]}
          dataChange={(e) => setuserData({ ...userData, gender: e.gender })}
        />
      </div>
      <div className="col-12 col-md-6 ">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          Country
        </label>
        <br />
        <Select
          options={currencies}
          property="country"
          dataChange={(e) => setuserData({ ...userData, country: e.country })}
        />
      </div>
      <div className="col-12 col-md-6 ">
        <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
          City
        </label>
        <br />
        <input
          type="email"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          placeholder="City Name"
          value={userData?.city}
          onChange={(e) => setuserData({ ...userData, city: e.target.value })}
        />
      </div>

      <div className="col-12 col-md-6 mt-3 m-auto" style={{ margin: "auto" }}>
        <button
          className="w-100 form_submit_btn2 text-white"
          onClick={submitHandler}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export default CreateAccount;
