import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Upload from "../components/Upload";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  applyPayment,
  otpSend,
  otpVerify,
} from "../redux/actions/serviceAction";
import { toast } from "react-toastify";
import OtpBox from "../components/OtpBox";
import { BsCheck2 } from "react-icons/bs";

const PaymentService = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { currencies, coins, wallets, setting, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const [isCopied, setisCopied] = useState(false);
  const [coinPrice, setcoinPrice] = useState(null);
  const [wallet, setwallet] = useState(null);
  const [paymentScreenshot, setpaymentScreenshot] = useState(null);
  const [invoiceScreenshot, setinvoiceScreenshot] = useState(null);
  const [otp, setotp] = useState("");

  const [countries, setcountries] = useState([]);

  const [paymentData, setpaymentData] = useState({
    senderEmail: "",
    recieverEmail: "",
    currency: null,
    coin: null,
    amount: "",
    totalTobePaid: "",
    bankDetails: "",
    paymentMode: "",
    country: "",
    city: "",
  });

  const calTotal = (data) => {
    if (!paymentData.amount) return;
    // if(!coinPrice) return

    const P = parseInt(paymentData.amount);
    const r = setting.paymentCharge * 0.01;
    const coin = data[paymentData?.coin?.id];

    console.log("Coin", coin);

    const coin_price = parseFloat(
      coin[paymentData.currency?.currency.toLowerCase()]
    );
    console.log(coin_price);
    const total = (P / coin_price) * r;

    setpaymentData({
      ...paymentData,
      totalTobePaid: total.toFixed(18),
    });
  };

  useEffect(() => {
    setwallet(wallets.find((wal) => wal?.coin?._id == paymentData.coin?._id));
    getCoinPrice(
      paymentData?.coin?.id,
      paymentData?.currency?.currency.toLowerCase()
    );
  }, [paymentData.coin, paymentData.currency, paymentData.amount]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!paymentData.senderEmail) {
      return toast.warn("Sender Email is required");
    }
    if (!paymentData.amount) {
      return toast.warn("Amount is required");
    }
    if (!paymentData.recieverEmail) {
      return toast.warn("Receiver's Email is required");
    }
    if (!paymentData.currency) {
      return toast.warn("Please Select Currency");
    }
    if (!paymentData.coin) {
      return toast.warn("Please Select Crypto/Coin");
    }
    if (!paymentData.bankDetails) {
      return toast.warn("Bank Details is required");
    }
    if (!paymentData.paymentMode) {
      return toast.warn("Please Select Payment Mode");
    }
    if (!paymentScreenshot) {
      return toast.warn("Payment Screenshot is required");
    }
    if (!otpVerified) {
      return toast.warn("Please verify sender email");
    }

    const form = new FormData();
    form.set("senderEmail", paymentData.senderEmail);
    form.set("recieverEmail", paymentData.recieverEmail);
    form.set("currency", paymentData.currency?._id);
    form.set("coin", paymentData.coin?._id);
    form.set("amount", paymentData.amount);
    form.set("totalTobePaid", paymentData.totalTobePaid);
    form.set("bankDetails", paymentData.bankDetails);
    form.set("paymentMode", paymentData.paymentMode);
    form.set("country", paymentData.paymentMode);
    form.set("city", paymentData.city);
    form.set("paymentScreenshot", paymentScreenshot);
    form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(applyPayment(form));
  };

  const getCoinPrice = async (coin, currency) => {
    if (!currency) return;
    if (!coin) return;
    try {
      const res = await axios.get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${coin}&vs_currencies=${currency}`
      );

      setcoinPrice(res.data);
      calTotal(res.data);
    } catch (error) {}
  };

  const fileChange = (e) => {
    console.log("file change 2");
    const reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    if (e.target.name == "file1") {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setpaymentScreenshot(reader.result);
        }
      };
    } else {
      reader.onload = () => {
        if (reader.readyState === 2) {
          setinvoiceScreenshot(reader.result);
        }
      };
    }
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);
  useEffect(() => {
    if (paymentData.currency) {
      const items = currencies.filter((c) => c.currency == paymentData.currency?.currency);
      setcountries(items);
    }
  }, [paymentData.currency]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (!paymentData.senderEmail) {
      return toast.error("Sender's Email is required");
    }

    dispatch(otpSend({ email: paymentData.senderEmail }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: paymentData.senderEmail, otp }));
  };

  return (
    <Layout  title="Payment Service ||" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">
                    Crypto Payments
                  </h1>
                  <h4 className="text-white">
                    <BsCheck2 size={30} className="m-1" /> Pay us in Crypto, we
                    pay your Invoice in Fiat Currency
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={30} className="m-1" />
                    Payments credited in 24 to 48 hours
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={30} className="m-1" />
                    Fast and secure transactions
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Sender Email"
                      >
                        Sender Email
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Sender Email"
                        id="Sender Email"
                        className="inp_control"
                        placeholder="Enter sender's email address"
                        value={paymentData.senderEmail}
                        disabled={otpVerified}
                        onChange={(e) =>
                          setpaymentData({
                            ...paymentData,
                            senderEmail: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Reciever’s Email"
                      >
                        Reciever’s Email
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Reciever’s Email"
                        id="Reciever’s Email"
                        className="inp_control"
                        placeholder="Enter reciever's email address"
                        value={paymentData.recieverEmail}
                        onChange={(e) =>
                          setpaymentData({
                            ...paymentData,
                            recieverEmail: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Currency"
                      >
                        Currency
                      </label>
                      <Select
                        activeOption={paymentData?.currency?.currency}
                        options={currencies}
                        property2="currency"
                        property="country"
                        dataChange={(item) =>
                          setpaymentData({
                            ...paymentData,
                            currency: item,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="text-white mb-1 fw600" htmlFor="Amount">
                        Amount
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Amount"
                        id="Amount"
                        className="inp_control"
                        placeholder="Enter amount"
                        value={paymentData.amount}
                        onChange={(e) =>
                          setpaymentData({
                            ...paymentData,
                            amount: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Period"
                      >
                        Crypto
                      </label>
                      <Select
                        activeOption={paymentData.coin?.name}
                        options={coins}
                        property="name"
                        property2={"code"}
                        img="253"
                        dataChange={(item) => {
                          setpaymentData({ ...paymentData, coin: item });
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="To be paid"
                      >
                        To be paid
                      </label>
                      <br />
                      <input
                        type="text"
                        name="To be paid"
                        id="To be paid"
                        className="inp_control"
                        value={paymentData.totalTobePaid}
                        disabled
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Payment Mode"
                      >
                        Payment Mode
                      </label>
                      <Select
                        activeOption="Normal"
                        options={[
                          { name: "WU payment", mode: "wu_payment" },
                          { name: "Bank Payment", mode: "bank_payment" },
                        ]}
                        property="name"
                        dataChange={(item) =>
                          setpaymentData({
                            ...paymentData,
                            paymentMode: item.mode,
                          })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Currency"
                      >
                        Country
                      </label>
                      <Select
                        activeOption={paymentData?.currency?.currency}
                        options={countries}
                        property="country"
                        dataChange={(item) =>
                          setpaymentData({
                            ...paymentData,
                            country: item.country,
                          })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Currency"
                      >
                        City
                      </label>
                      <input
                        className="inp_control"
                        placeholder="City Name"

                        value={paymentData.city}
                        onChange={(e) =>
                          setpaymentData({
                            ...paymentData,
                            city: e.target.value,
                          })
                        }
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Send Crypto to"
                      >
                        Send Crypto to
                      </label>
                      <input
                        type="text"
                        name="Reciever’s Email"
                        id="Reciever’s Email"
                        className="inp_control"
                        value={
                          wallet?.address ? wallet.address : "Select Coin first"
                        }
                        disabled
                        style={{ fontSize: "14px" }}
                      />
                      <CopyToClipboard
                        text={wallet?.address}
                        onCopy={() => setisCopied(true)}
                      >
                        <button className="copyButton">Copy Address</button>
                      </CopyToClipboard>
                    </div>
                    {isCopied && <p className="copyText">Address copied</p>}
                    <div className="col-12 ">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Postal Address"
                      >
                        WU Details/Bank Details
                      </label>
                      <textarea
                        name=""
                        id=""
                        rows="6"
                        className="w-100 inp_control_textarea"
                        placeholder="WU Details/Bank Details"
                        value={paymentData.bankDetails}
                        onChange={(e) =>
                          setpaymentData({
                            ...paymentData,
                            bankDetails: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                    {/* <div className="col-12 col-md-6 align-self-end pb-2">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Postal Address"
                      >
                        Attach Invoice
                      </label>
                      <Upload
                        name={"file2"}
                        onChange={fileChange}
                        src={invoiceScreenshot}
                      >
                        <div className="d-none d-md-block">
                          Attach Invoice (If Any)
                        </div>
                        <div className="d-block d-md-none">
                          Attach Invoice (If Any)
                        </div>
                      </Upload>
                    
                    </div> */}

                    {otpSent && !otpVerified && (
                      <OtpBox
                        value={otp}
                        onChange={(value) => setotp(value)}
                        onClick={verifyOtp}
                      />
                    )}

                    <div className="col-12 col-md-6 align-self-end pb-2">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Attach screenshot  of Transfer "
                      >
                        Attach screenshot of Transfer
                      </label>
                      <Upload
                        onChange={fileChange}
                        src={paymentScreenshot}
                        name={"file1"}
                      >
                        <div className="d-none d-md-block">
                          Drag & Drop your Files Here
                        </div>
                        <div className="d-block d-md-none">Attach</div>
                      </Upload>
                    </div>

                    <div className="col-12 mt-5 d-flex justify-content-center">
                      {!otpSent ? (
                        <button
                          className="form_submit_btn text-white"
                          onClick={sendOtp}
                        >
                          Send OTP
                        </button>
                      ) : (
                        <button
                          className="form_submit_btn text-white"
                          onClick={formSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                  </div>
                  <h5 className="text-white mt-2">
                    {" "}
                    *For assistance or enquiries contact Support@99krypto.com
                    with your transaction ID and email address
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>:Fill the form, provide
                    the vendor details and send the crypto to the mentioned
                    address along with the screenshot of the transfer and
                    complete the submission
                    <br />
                    <span className="color4"> 2</span>:We pay the vendor as per
                    the chosen method of payment and the transfer details will
                    be emailed to you with the necessary receipt for the same
                    <br />
                    <span className="color4"> 3</span>: In some cases we tend to
                    ask KYC of the sender due to compliance requirement. If any
                    such requirements, we will send you an email for further
                    details
                    <br />
                    <span className="color4"> 4</span>:We will transfer the
                    funds to the payee within 24 to 48 hours, subjected to
                    confirmations
                    <br />
                    <span className="color4"> 5</span>: You can contact us at
                    support@99krypto.com for any questions
                    <br />
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PaymentService;
