import React, { useState, useEffect } from "react";
import Layout from "../layout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { getCryptoBuy, getCryptoSell } from "../redux/actions/dealerAction";
import AcceptedBuy from "../components/Account/AcceptedBuy";
import { getUserData, loadUser } from "../redux/actions/userAction";
import Payments from "../components/UserProfile/Payments";
import Profile from "../components/UserProfile/Profile";
import CryptoLoans from "../components/UserProfile/CryptoLoans";
import CashLoans from "../components/UserProfile/CashLoans";
import Escrows from "../components/UserProfile/Escrows";
import Buy from "../components/UserProfile/Buy";
import Sell from "../components/UserProfile/Sell";
import Swaps from "../components/UserProfile/Swaps";
import Deposits from "../components/UserProfile/Deposits";

const Account = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/sign-in");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    dispatch(getUserData(user._id));
  }, []);

  return (
    <Layout title="Dealer's Dashboard">
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-12">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-0 text-white display-5 fw500">
                    Your Profile
                  </h1>
                  <br />
                  <div className="row gy-4">
                    <Tabs>
                      <TabList>
                        <Tab>Account</Tab>
                        <Tab>Cash Loans</Tab>
                        <Tab>Crypto Loans</Tab>
                        <Tab>Escrows</Tab>
                        <Tab>Payments</Tab>
                        <Tab>Deposits</Tab>
                        <Tab>Buy</Tab>
                        <Tab>Sell</Tab>
                        <Tab>Swaps</Tab>
                      </TabList>
                      <TabPanel>
                        <Profile />
                      </TabPanel>
                      <TabPanel>
                        <CashLoans />
                      </TabPanel>
                      <TabPanel>
                        <CryptoLoans />
                      </TabPanel>
                      <TabPanel>
                        <Escrows />
                      </TabPanel>
                      <TabPanel>
                        <Payments />
                      </TabPanel>
                      <TabPanel>
                        <Deposits />
                      </TabPanel>
                      <TabPanel>
                        <Buy />
                      </TabPanel>
                      <TabPanel>
                        <Sell />
                      </TabPanel>
                      <TabPanel>
                        <Swaps />
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
