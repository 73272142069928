import React, { useState, useRef } from "react";

const Select = ({
  activeOption,
  options,
  property,
  property2,
  img,
  dataChange,
}) => {
  const options_container = useRef(null);
  const [active, setActive] = useState("Select");

  const displayBlock = () => {
    options_container.current.style.display = "block";
  };

  const displayNone = () => {
    options_container.current.style.display = "none";
  };

  if (typeof options !== "object") {
    return (
      <div className="col-12 col-md-6">
        <input
          type="number"
          name="Loan Amount"
          id="Loan Amount"
          className="inp_control"
          value={"Loading"}
        />
      </div>
    );
  }

  return (
    <div
      onMouseOver={displayBlock}
      onMouseLeave={displayNone}
      className="w-100 module-border-wrap select"
    >
      <div className="module text-center w-100">
        <div className="d-flex align-items-center justify-content-between w-100 h-100 color4 f14 ps-3 pe-2">
          <div className="text-dark fw500 f20">{active}</div>
          <div>
            <img src="assets/arrow.svg" alt="" />
          </div>
        </div>
      </div>

      {activeOption !== "Price" && (
        <ul ref={options_container} className="options_container list-unstyled">
          {options?.map((item, i) => {
            return (
              <li
                className=" py-1 "
                onClick={() => {
                  dataChange(item);
                  setActive(property2 ? item[property2] : item[property]);
                  options_container.current.style.display = "none";
                }}
                key={i}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0 10px",
                  }}
                >
                  {img && (
                    <div style={{ flex: "1" }}>
                      {" "}
                      <img
                        style={{
                          width: "30px",
                          alignSelf: "flex-start",
                          height: "30px",
                          borderRadius: "50%",
                          transform: "rotate(360deg)",
                        }}
                        src={item?.logo?.url}
                      />
                    </div>
                  )}

                  <span
                    style={{
                      flex: "1",
                      textAlign:   "left" ,
                    }}
                  >
                    {item[property]}{" "}
                  </span>
                  {property2 && (
                    <span style={{ textAlign: "right", width: "80px" }}>
                      {item[property2] && item[property2]}
                    </span>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default Select;
