import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAcceptedBuy } from "../../redux/actions/dealerAction";

const AcceptedBuy = () => {
  const dispatch = useDispatch();
  const { acceptedBuy } = useSelector((state) => state.dealer);

  useEffect(() => {
    dispatch(getAcceptedBuy());
  }, []);

  return (
    <div>
      <div className="items">
        <div className="buyCard owned">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
        </div>
        {acceptedBuy?.map((item, index) => (
          <div key={index} className="buyCard owned">
            <div>
              <span className="key"> Transaction ID :</span>{" "}
              <span> {item.transactionId} </span>
            </div>
            <div>
              <span className="key">Status :</span>{" "}
              <span className={item.status}>{item.status}</span>{" "}
            </div>

            <div>
              <span className="key">Amount :</span> <span>{item.amount} </span>{" "}
            </div>
            <div>
              <span className="key">Currency :</span>{" "}
              <span>{item.currency?.currency} </span>
            </div>
            <div>
              <span className="key">Quantity :</span>{" "}
              <span> {item.quantity.toFixed(6)}</span>{" "}
            </div>

            <div>
              <span className="key">Coin :</span>{" "}
              <span> {item.coin?.name}</span>{" "}
            </div>
            <div>
              <span className="key">Country:</span> <span>{item.country}</span>{" "}
            </div>
            <div>
              <span className="key">City:</span> <span>{item.city}</span>{" "}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default AcceptedBuy;
