import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { acceptBuy, getCryptoBuy } from "../../redux/actions/dealerAction";
import { userDetailsReducer } from "../../redux/reducers/userReducer";

const Buy = () => {
  const dispatch = useDispatch();
  const { buy } = useSelector((state) => state.dealer);
  const { user } = useSelector((state) => state.user);
  const [securityCode, setsecurityCode] = useState("");

  useEffect(() => {
    dispatch(getCryptoBuy());
  }, []);

  const clickHandler = (id) => {
    dispatch(acceptBuy(id, { securityCode }));

    setsecurityCode("");
  };

  const isBlocked = (item) => {
    const length = item?.tried.filter((i) => i == user._id).length;

    return length >= 3 ? true : false;
  };
  return (
    <div>
      <div className="items">
        <div className="buyCard">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Amount</div>
          <div>Currency</div>
          <div>Quantity</div>
          <div>Coin</div>
          <div>Country</div>
          <div>City</div>
          <div>Accept</div>
        </div>
        {buy?.map((item, index) => (
          <div key={index} className="buyCard">
            <div>
              <strong className="key">Transation ID :</strong>{" "}
              <span>{item.transactionId}</span>{" "}
            </div>
            <div>
              <strong className="key">Status:</strong>{" "}
              <span> {item.status}</span>{" "}
            </div>

            <div>
              <strong className="key">Amount:</strong>{" "}
              <span>{item.amount}</span>{" "}
            </div>
            <div>
              <strong className="key">Currency:</strong>{" "}
              <span>{item.currency?.currency} </span>{" "}
            </div>
            <div>
              <strong className="key">Quantity:</strong>{" "}
              <span>{item.quantity}</span>{" "}
            </div>

            <div>
              <strong className="key">Coin:</strong>{" "}
              <span>{item.coin?.name}</span>{" "}
            </div>
            <div>
              <strong className="key">Country:</strong>
              <span> {item.country}</span>{" "}
            </div>
            <div>
              <strong className="key">City:</strong> <span>{item.city}</span>{" "}
            </div>
            {isBlocked(item) ? (
              <div>
                <button className="btn btn-danger">Blocked</button>{" "}
              </div>
            ) : (
              <div>
                <input
                  type="number"
                  value={securityCode}
                  placeholder="Security Code"
                  onChange={(e) => setsecurityCode(e.target.value)}
                />
                <button
                  className="btn btn-primary"
                  onClick={() => clickHandler(item._id)}
                >
                  Accept
                </button>{" "}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Buy;
