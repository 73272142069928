import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const DepositTrack = () => {
  const { track: depositData } = useSelector((state) => state.service);

  return (
    <>
      <div className="col-12 col-lg-12">
        <div className="apply_loan_right_container p-4">
          <h1 className="mb-0 text-white display-5 fw500"> Deposits</h1>
          <br />
          <div className="row gy-4">

          <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
                Status
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                placeholder="Enter quantity"
                value={depositData?.status}
                disabled
              />
            </div>
            <div className="col-12 col-md-6">
              <label
                className="text-white mb-1 fw600"
                htmlFor="Collateral Required"
              >
               Deposit Coin
              </label>
              <br />
              <input
                type="text"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                value={depositData.depositingCoin?.name}
                placeholder="Interest rate"
                disabled
              />
            </div>

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
               Deposit Quantity
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                placeholder="Enter quantity"
                value={depositData.amount}
                disabled
              />
            </div>
            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Loan Amount">
              Recieving at Maturity
              </label>
              <br />
              <input
                type="text"
                name="Loan Amount"
                id="Loan Amount"
                className="inp_control"
                placeholder="Enter quantity"
                value={depositData.recieving}
                disabled
              />
            </div>
          
          
         
           
            

            <div className="col-12 col-md-6">
              <label className="text-white mb-1 fw600" htmlFor="Email Address">
              Deposit Maturity Date
              </label>
              <input
                type="email"
                name="Collateral Required"
                id="Collateral Required"
                className="inp_control"
                placeholder="Enter your email"
                value={moment(depositData?.depositEndDate).format(
                  "MMMM Do YYYY, h:mm:ss a"
                )}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepositTrack;
