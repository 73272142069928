import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { kycRegister } from "../../redux/actions/userAction";
import BackCardVerify from "./BackCardVerify";
import FrontCardVerify from "./FrontCardVerify";
import SelectCardType from "./SelectCardType";
import Selfie from "./Selfie";

const PhotoVerification = ({ userData, children }) => {
  const [step, setstep] = useState(1);
  const [facingMode, setfacingMode] = useState("environment");

  const dispatch = useDispatch();
  const videoRef = useRef();
  const [imageData, setimageData] = useState({
    cardType: "",
    idFront: null,
    idBack: null,
    selfie: null,
  });

  useEffect(() => {
    getVideo("environment");
  }, [videoRef]);

  const getVideo = (mode) => {
    navigator.mediaDevices
      .getUserMedia({
        video: true,
        video: {
          facingMode: mode,
          aspectRatio: 16 / 9,
        },
      })
      .then((stream) => {
        let video = videoRef.current;
        video.srcObject = stream;
        video.onloadedmetadata = function (e) {
          video.play();
        };
      })
      .catch((err) => {
        console.error("error:", err);
      });
  };

  useEffect(() => {
    if (step == 3) {
      setfacingMode("user");
      getVideo("user");
      console.log("changed mode");
    }
  }, [step]);

  const getComponent = () => {
    switch (step) {
      case 0:
        return (
          <SelectCardType
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
          />
        );
        break;
      case 1:
        return (
          <FrontCardVerify
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
          />
        );
        break;
      case 2:
        return (
          <BackCardVerify
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
          />
        );
        break;
      case 3:
        return (
          <Selfie
            imageData={imageData}
            setimageData={setimageData}
            videoRef={videoRef}
            step={step}
          />
        );
        break;

      default:
        break;
    }
  };

  const submitHandler = () => {
    const form = new FormData();

    form.append("idFront", imageData.idFront);
    form.append("idBack", imageData.idBack);
    form.append("selfie", imageData.selfie);
    form.append("_id", userData._id);
    form.append("email", userData.email);

    dispatch(kycRegister(form));
  };

  console.log(step);

  return (
    <div className="card-verify">
      <div className="photo-taker">
        <div style={{ display: step == 3 ? "none" : "" }}>
          <video
            ref={videoRef}
            className="card-placeholder"
            style={{ border: "2px solid" }}
          ></video>
        </div>
      </div>

      {getComponent()}

      <div className="col-8 m-auto">
        {step == 3 && imageData?.selfie ? (
          <button
            className="w-100 form_submit_btn2 text-white "
            onClick={submitHandler}
          >
            Submit
          </button>
        ) : (
          <button
            className="w-100 form_submit_btn2 text-white "
            onClick={() => setstep(step + 1)}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export default PhotoVerification;
