export const navigations = [
  {
    text: "Home",
    link: "/",
  },
  {
    text: "Cash Loans",
    link: "/cash-loans",
  },
  {
    text: "Crypto Loans",
    link: "/crypto-loans",
  },
  {
    text: "Deposits",
    link: "/deposits",
  },
  {
    text: "Buy/Sell",
    link: "/buy-sell",
  },
  {
    text: "Swap",
    link: "/swap",
  },
  {
    text: "Payments",
    link: "/payment-services",
  },
  {
    text: "Escrow",
    link: "/escrow",
  },
  {
    text: "Find Transaction",
    link: "/find",
  },
  {
    text: "Press",
    link: "/about-us",
  },
  {
    text: "White Paper",
    link: "/white-paper",
  },
];
