import React, { useState, useEffect } from "react";
import Layout from "../layout";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Account from "../components/Account/Account";
import Buy from "../components/Account/Buy";
import Sell from "../components/Account/Sell";
import { getCryptoBuy, getCryptoSell } from "../redux/actions/dealerAction";
import AcceptedBuy from "../components/Account/AcceptedBuy";
import AcceptedSell from "../components/Account/AcceptedSell";
import { loadUser } from "../redux/actions/userAction";
import CashLoans from "../components/Account/CashLoans";
import TakenCashLoan from "../components/Account/TakenCashLoan";
import Payments from "../components/Account/Payments";
import TakenPaymets from "../components/Account/TakenPayments";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      return history.push("/dealer");
    }
    if (user.role!=="dealer") {
      return history.push("/profile");
    }
  }, [isAuthenticated]);

  useEffect(() => {
  }, []);

  return (
    <Layout title="Dealer's Dashboard" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-12">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-0 text-white display-5 fw500">
                    Your Profile
                  </h1>
                  <br />
                  <div className="row gy-4">
                    <Tabs>
                      <TabList>
                        <Tab>Account</Tab>
                        <Tab>Buy</Tab>
                        <Tab>Sell</Tab>
                        <Tab>Cash Loan</Tab>
                        <Tab>Payment</Tab>
                        <Tab>Owned Buy</Tab>
                        <Tab>Owned Sell</Tab>
                        <Tab>Taken order (CAL)</Tab>
                        <Tab>Taken Order(PYMT)</Tab>
                      </TabList>
                      <TabPanel>
                        <Account />
                      </TabPanel>
                      <TabPanel>
                        <Buy />
                      </TabPanel>
                      <TabPanel>
                        <Sell />
                      </TabPanel>
                      <TabPanel>
                        <CashLoans />
                      </TabPanel>
                      <TabPanel>
                        <Payments />
                      </TabPanel>
                      <TabPanel>
                        <AcceptedBuy />
                      </TabPanel>
                      <TabPanel>
                        <AcceptedSell />
                      </TabPanel>
                      <TabPanel>
                        <TakenCashLoan />
                      </TabPanel>
                      <TabPanel>
                        <TakenPaymets />
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Dashboard;
