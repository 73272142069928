import React from "react";
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin ,FaTelegram} from "react-icons/fa";

const Footer = () => {
  return (
    <div className="footer_container">
      <div className="page_container">
        <div className="container-fluid">
          <div className="d-flex justify-content-center gap-3">
            <a
              href="https://t.me/Krypto99_com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="mb-3">
                <FaTelegram fontSize={35} />
              </div>
            </a>

            <a href="https://www.instagram.com/invites/contact/?i=mckqzlvy0bc3&utm_content=nsemo0f" target="_blank" rel="noopener noreferrer">
              <div className="">
                <FaInstagram fontSize={35} />
              </div>
            </a>

            <a
              href="https://twitter.com/99krypto_com?t=jmDzZstyNpGdQly1Yxed_g&s=09"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="">
                <FaTwitter fontSize={35} />
              </div>
            </a>
            {/* <a href="http://" target="_blank" rel="noopener noreferrer">
              <div className="">
                <FaLinkedin fontSize={35} />
              </div>
            </a> */}
          </div>

          <p className="text-center mb-0">2022 / all the rights reserved</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
