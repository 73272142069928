import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Checkbox from "../components/Checkbox";
import { Link, useHistory } from "react-router-dom";
import Upload from "../components/Upload";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  applyEscrow,
  otpSend,
  otpVerify,
} from "../redux/actions/serviceAction";
import OtpBox from "../components/OtpBox";
import { BsCheck2 } from "react-icons/bs";

const EscrowService = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [wallet, setwallet] = useState("");
  const [isCopied, setisCopied] = useState(false);

  const [recieverWallet, setrecieverWallet] = useState(null);
  const [isChecked, setisChecked] = useState(false);
  const [otp, setotp] = useState("");

  const {
    currencies,
    timePeriods,
    coins,
    wallets,
    loading,
    setting,
    success,
    otpSent,
    otpVerified,
  } = useSelector((state) => state.service);
  const [quantity, setquantity] = useState(0);

  const [screenshot, setscreenshot] = useState(null);

  const [escrowData, setescrowData] = useState({
    email1: "",
    email2: "",
    party1: "",
    party2: "",
    coin: null,
    quantity: null,
    description: "",
    recieverWallet: "",
    crypto: "",
    totalTobePaid: 0,
  });

  useEffect(() => {
    setescrowData({
      ...escrowData,
      totalTobePaid: quantity * parseInt(setting?.escrowCharge) * 0.01,
    });
  }, [quantity]);

  console.log(setting);
  console.log(setescrowData.totalTobePaid);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!escrowData.email1) {
      return toast.warn("Email1 is required");
    }
    if (!escrowData.email2) {
      return toast.warn("Email2 is required");
    }
    if (!quantity) {
      return toast.warn("Quantity is required");
    }
    if (!escrowData.coin) {
      return toast.warn("Please Select Crypto/Coin");
    }
    if (!escrowData.recieverWallet) {
      return toast.warn("Please Select Crypto/Coin");
    }

    if (!screenshot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please Check Mark Terms & Conditions");
    }

    const form = new FormData();
    form.set("email1", escrowData.email1);
    form.set("email2", escrowData.email2);
    form.set("party1", escrowData.party1);
    form.set("party2", escrowData.party2);
    form.set("quantity", quantity);
    form.set("coin", escrowData.coin?._id);
    form.set("description", escrowData.description);
    form.set("recieverWallet", escrowData.recieverWallet);
    form.set("totalTobePaid", escrowData.totalTobePaid);
    form.set("screenshot", screenshot);
    // form.set("invoiceScreenshot", invoiceScreenshot);

    dispatch(applyEscrow(form));
  };

  useEffect(() => {
    setwallet(wallets.find((wal) => wal?.coin?._id == escrowData.coin?._id));
  }, [escrowData.coin]);

  const fileChange = (e) => {
    
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setscreenshot(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();
    if (!escrowData.email1) {
      return toast.error("Email is required");
    }

    dispatch(otpSend({ email: escrowData.email1 }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: escrowData.email1, otp }));
  };

  return (
    <Layout  title="Escrow Service || 99 Krypto" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">
                    Escrow Service
                  </h1>
                  <h4 className="text-white">
                    <BsCheck2 size={26} className="mr-1" /> We govern your
                    agreements for goods and services by being a third party.
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={26} className="mr-1" /> We receive and
                    disburse the crypto payments on meeting the terms of
                    agreement set by transacting parties.
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={26} className="mr-1" /> Immediate disbursal
                    of crypto payments as soon as the parties agree to release
                    the payment or pre-set agreement terms are met by payment
                    receiving party.
                  </h4>
                  <h4 className="text-white">
                    <BsCheck2 size={26} className="mr-1" /> Safeguards buyer and
                    seller, no counter party risk
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Party 1"
                      >
                        Party 1
                      </label>
                      <Select
                        activeOption="20 Coin"
                        options={[
                          { name: "Buyer", value: "buyer" },
                          { name: "Seller", value: "seller" },
                        ]}
                        property="name"
                        dataChange={(item) =>
                          setescrowData({
                            ...escrowData,
                            party1: item.value,
                            party2: item.value == "buyer" ? "seller" : "buyer",
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Email Address"
                      >
                        Email Address
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Email Address"
                        id="Email Address"
                        className="inp_control"
                        placeholder="Email address of party 1"
                        value={escrowData.email1}
                        onChange={(e) =>
                          setescrowData({
                            ...escrowData,
                            email1: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Party 2"
                      >
                        Party 2
                      </label>
                      <input
                        type="text"
                        name="Email Address"
                        id="Email Address"
                        className="inp_control"
                        placeholder="Select Party1 "
                        value={escrowData.party2}
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Email Address"
                      >
                        Email Address
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Email Address"
                        id="Email Address"
                        className="inp_control"
                        placeholder="Email address of party 2"
                        value={escrowData.email2}
                        onChange={(e) =>
                          setescrowData({
                            ...escrowData,
                            email2: e.target.value,
                          })
                        }
                      />
                    </div>

           
                    {/* <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Agreement If any"
                      >
                        Agreement If any
                      </label>
                      <br />
                      <Upload onChange={fileChange}>
                        <div className="d-none d-md-block">
                          Drag & Drop your Files Here
                        </div>
                        <div className="d-block d-md-none">Attach</div>
                      </Upload>
                    </div> */}
                       <div className="col-12 col-md-6">
                      <label className="text-white mb-1 fw600" htmlFor="Amount">
                        Quantity
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Amount"
                        id="Amount"
                        className="inp_control"
                        value={quantity}
                        placeholder="Enater quantity"
                        onChange={(e) => setquantity(e.target.value)}
                      />
                    </div>

                    <div className="col-12 col-md-6">
                      <label className="text-white mb-1 fw600" htmlFor="Crypto">
                        Crypto
                      </label>
                      <Select
                        activeOption={escrowData.coin?.name}
                        options={coins}
                        property="name"
                        property2={"code"}
                        img="253"
                        dataChange={(item) => {
                          setescrowData({ ...escrowData, coin: item });
                          setwallet(wallets.find((w) => item._id === w.coin));
                        }}
                      />
                    </div>
                    <div className="col-12 ">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Description  of Agreement"
                      >
                        Description of Agreement
                      </label>
                      <br />
                      <textarea
                        className="w-100 inp_control_textarea"
                        name=""
                        id=""
                        rows="6"
                        placeholder="Description of Agreement...."
                        value={escrowData.description}
                        onChange={(e) =>
                          setescrowData({
                            ...escrowData,
                            description: e.target.value,
                          })
                        }
                      ></textarea>
                    </div>
                 
                    <div className="col-12 col-md-6">
                      <label className="text-white mb-1 fw600" htmlFor="Fee %">
                        Fee %
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Fee %"
                        id="Fee %"
                        className="inp_control"
                        // placeholder="1 %"
                        value={`${setting?.escrowCharge - 100} %`}
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Total Pay"
                      >
                        Total Pay
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Total Pay"
                        id="Total Pay"
                        className="inp_control"
                        placeholder="Total..."
                        value={parseFloat(escrowData.totalTobePaid)}
                        disabled
                      />
                    </div>
                    <div className="col-12  col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Now Send your Crypto to"
                      >
                        Receiver's Wallet Address
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Receiver's Wallet Address"
                        id="Receiver's Wallet Address"
                        className="inp_control"
                        placeholder="Reciever's wallet address(Enter carefully)"
                        value={escrowData.recieverWallet}
                        onChange={(e) =>
                          setescrowData({
                            ...escrowData,
                            recieverWallet: e.target.value,
                          })
                        }
                      />
                    </div>
                    {isCopied && <p className="copyText">Address copied</p>}
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Now Send your Crypto to"
                      >
                        Now Send your Crypto to
                      </label>
                      <br />
                      <input
                        style={{ fontSize: "14px" }}
                        type="text"
                        name="Now Send your Crypto to"
                        id="Now Send your Crypto to"
                        className="inp_control"
                        value={
                          wallet?.address ? wallet?.address : "Select Coin"
                        }
                        disabled
                      />
                      <CopyToClipboard
                        text={wallet?.address}
                        onCopy={() => setisCopied(true)}
                      >
                        <button className="copyButton">Copy Address</button>
                      </CopyToClipboard>
                    </div>

                    {otpSent && !otpVerified && (
                      <OtpBox
                        value={otp}
                        onChange={(value) => setotp(value)}
                        onClick={verifyOtp}
                      />
                    )}
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Screen shot of Transfer"
                      >
                        Screen shot of Transfer
                      </label>
                      <br />
                      <Upload onChange={fileChange} src={screenshot}>
                        <div className="d-none d-md-block">
                          Drag & Drop your Files Here
                        </div>
                        <div className="d-block d-md-none">Attach</div>
                      </Upload>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-items-center gap-3 mt-5">
                      <Checkbox
                        onClick={() => setisChecked(!isChecked)}
                        active={isChecked}
                      />
                      <span className="fw600 text-white f18">
                        Terms & Conditions
                      </span>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                      {!otpSent ? (
                        <button
                          className="form_submit_btn text-white"
                          onClick={sendOtp}
                        >
                          Send OTP
                        </button>
                      ) : (
                        <button
                          className="form_submit_btn text-white"
                          onClick={formSubmit}
                        >
                          Submit
                        </button>
                      )}
                    </div>
                    <h5 className="text-white">
                      *For assistance or enquiries please contact
                      support@99krypto.com with your email and transaction ID
                    </h5>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>: . Fill the form,
                    furnish the details regarding terms, conditions of your
                    agreement and send us an email attachment if any documents
                    for the same to our support email address mentioning the
                    Transaction ID and email address after completing the
                    transaction
                    <br />
                    <span className="color4"> 2</span>:Send the crypto to
                    our wallet and attach the transfer screenshot and complete
                    the submission
                    <br />
                    <span className="color4"> 3</span>: We will send an
                    email to the second party to accept the offer
                    <br />
                    <span className="color4"> 4</span>: As soon as we
                    receive the acceptance from 2nd party agreement is set to be
                    live.
                    <br />
                    <span className="color4"> 5</span>:. After completion of
                    the agreement as per the terms and conditions, we will
                    release the funds to the 2nd party.
                    <br />
                    <span className="color4"> 6</span>: In case of any
                    discrepancies both the parties can approach us to settle the
                    transaction.
                    <br />
                    <span className="color4"> 7</span>: In this case we will
                    investigate and finalize the appropriate party to transfer
                    the payment
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default EscrowService;
