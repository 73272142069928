import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Checkbox from "../components/Checkbox";
import { useHistory } from "react-router-dom";
import Upload from "../components/Upload";
import { useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { useDispatch } from "react-redux";
import { applyLend, otpSend, otpVerify } from "../redux/actions/serviceAction";
import axios from "axios";
import { toast } from "react-toastify";
import OtpBox from "../components/OtpBox";
import { BsCheckLg } from "react-icons/bs";

const ApplyLend = () => {
  const dispatch = useDispatch();
  const {
    timePeriods,
    coins,
    wallets,
    success,
    otpSent,
    otpVerified,
    setting,
  } = useSelector((state) => state.service);
  const history = useHistory();

  const [isCopied, setisCopied] = useState(false);
  const [isChecked, setisChecked] = useState(false);

  const [screenShot, setscreenShot] = useState(null);

  const [cryptoWallets, setcryptoWallets] = useState([]);
  const [collateralCoinPrice, setcollateralCoinPrice] = useState("");

  const [wallet, setwallet] = useState("");
  const [otp, setotp] = useState("");
  const [periods, setperiods] = useState([]);
  // const [otpSent, setotpSent] = useState(false);
  const [addContent, setAddContent] = useState(false);

  const [loanData, setloanData] = useState({
    quantity: 0,
    lendCoin: "",
    collateralRequired: "",
    timePeriod: {
      interest: "",
    },
    totalTobePaid: "0",
    walletAddress: "",
    collateralCoin: null,
  });

  const calTotal = () => {
    if (!loanData.quantity) return;
    if (!loanData.timePeriod?.numberOfMonths) return;

    const P = loanData.quantity;
    const r = loanData?.timePeriod?.interest * 0.01;
    const t = parseInt(loanData.timePeriod?.numberOfMonths) / 12;
    const total = P * (1 + r * t);
    setloanData({
      ...loanData,
      totalTobePaid: total.toFixed(6),
    });
  };
  const formSubmit = (e) => {
    e.preventDefault();

    if (!loanData.email) {
      return toast.warn("Email is required");
    }
    if (!loanData.walletAddress) {
      return toast.warn("Wallet Address is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot Is Required");
    }
    if (!isChecked) {
      return toast.warn("Please Checkmark Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Verify your email..");
    }

    const form = new FormData();
    form.set("email", loanData.email);
    form.set("timePeriod", loanData.timePeriod._id);
    form.set("quantity", loanData.quantity);
    form.set("collateralRequired", loanData.collateralRequired);

    form.set("totalTobePaid", loanData.totalTobePaid);

    form.set("collateralCoin", loanData.collateralCoin._id);
    form.set("lendCoin", loanData.lendCoin._id);
    form.set("walletAddress", loanData.walletAddress);
    form.set("screenShot", screenShot);

    dispatch(applyLend(form));
  };

  const getCoinPrice = async (loanCoin, collateralCoin) => {
    if (!loanCoin) return;
    if (!collateralCoin) return;
    const res = await axios.get(
      `https://api.coingecko.com/api/v3/simple/price?ids=${loanCoin},${collateralCoin}&vs_currencies=usd`
    );
    const lendCoin = res.data[loanData?.lendCoin?.id];
    const _collateralCoin = res.data[loanData?.collateralCoin?.id];
    const lendCoinPrice = lendCoin["usd"];
    const collateralCoinPrice = _collateralCoin["usd"];
    const qtyPrice = loanData.quantity * lendCoinPrice;
    const percent = setting.lendCharge * 0.01;
    const d = qtyPrice / percent;
    const collateralRequired = d / collateralCoinPrice;
    setcollateralCoinPrice(parseFloat(_collateralCoin["usd"]));

    setloanData({
      ...loanData,
      collateralRequired: collateralRequired,
    });
  };

  useEffect(() => {
    calTotal();
  }, [loanData.timePeriod, loanData.quantity]);

  useEffect(() => {
    console.log(loanData.collateralCoin);
    if (!loanData.collateralCoin) return;
    const _periods = timePeriods.filter(
      (P) =>
        P.coin._id == loanData.collateralCoin?._id && P.for == "crypto-loan"
    );

    console.log(_periods);

    setperiods(_periods);
    setloanData({ ...loanData, timePeriod: { interest: "" } });
  }, [loanData.collateralCoin]);

  const fileChange = (e) => {
    
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    setcryptoWallets(wallets);
  }, [wallets]);

  useEffect(() => {
    setwallet(
      cryptoWallets.find(
        (wal) => wal?.coin?._id == loanData.collateralCoin?._id
      )
    );
    getCoinPrice(loanData?.lendCoin.id, loanData?.collateralCoin?.id);
  }, [loanData.collateralCoin, loanData.lendCoin, loanData.quantity]);

  const applyHandler = () => {
    if (!loanData?.lendCoin?.name) {
      return toast.warn("Please select Lend Coin");
    }
    if (!loanData.quantity) {
      return toast.warn("Please add Quantity");
    }

    if (!loanData?.collateralCoin?.name) {
      return toast.warn("Please select Collateral Coin");
    }
    if (!loanData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    setAddContent(true);
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: loanData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: loanData.email, otp }));
  };

  return (
    <Layout  title="Crypto Loan" >
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">
                    Crypto Loans
                  </h1>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> USDT or USDC or any Crypto to
                    Crypto Loan{" "}
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> Interest payment in Crypto{" "}
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> No Monthly Interest Payments
                  </h4>
                  <h4 className="text-white">
                    {" "}
                    <BsCheckLg className="m-2" /> Loan Credited to your wallet
                    in 2 hours*{" "}
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Coin"
                      >
                        Loan Coin
                      </label>
                      {coins && (
                        <Select
                          activeOption={loanData.lendCoin?.name}
                          options={coins}
                          property="name"
                          property2={"code"}
                          img="253"
                          dataChange={(item) => {
                            setloanData({ ...loanData, lendCoin: item });
                          }}
                        />
                      )}
                    </div>

                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Loan Quantity
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        value={loanData.quantity}
                        onChange={(e) =>
                          setloanData({
                            ...loanData,
                            quantity: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Coin"
                      >
                        Collateral Coin
                      </label>
                      {coins && (
                        <Select
                          activeOption={loanData.collateralCoin?.name}
                          options={coins}
                          property="name"
                          property2={"code"}
                          img="253"
                          dataChange={(item) => {
                            setloanData({ ...loanData, collateralCoin: item });
                            setwallet(wallets.find((w) => item._id === w.coin));
                          }}
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Required"
                      >
                        Collateral Required
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Collateral Required"
                        id="Collateral Required"
                        className="inp_control"
                        value={`${
                          loanData?.collateralRequired
                            ? loanData?.collateralRequired
                            : "0"
                        }`}
                        disabled={true}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Period"
                      >
                        Loan Period
                      </label>
                      {timePeriods && (
                        <Select
                          activeOption={loanData?.timePeriod?.name}
                          options={periods}
                          property="name"
                          dataChange={(item) =>
                            setloanData({ ...loanData, timePeriod: item })
                          }
                        />
                      )}
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Interest % APY"
                      >
                        Interest % APR
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Interest % APY"
                        id="Interest % APY"
                        className="inp_control"
                        value={`${loanData?.timePeriod?.interest} %`}
                        disabled={true}
                      />
                    </div>

                    <div className="col-12 col-md-6 d-flex flex-column">
                      <label className="text-white mb-1 fw600" htmlFor="Coin">
                        Total to be paid back
                      </label>
                      <div className=" d-flex">
                        {/* <button>Total</button> */}
                        <input
                          type="text"
                          name="Coin"
                          id="Coin"
                          className="inp_control"
                          value={`${loanData?.totalTobePaid} ${
                            loanData?.currency?.currency
                              ? loanData?.currency?.currency
                              : ""
                          }`}
                          disabled={true}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6 d-flex flex-column">
                      <label className="text-white mb-1 fw600" htmlFor="Coin">
                        Collateral Coin Price
                      </label>
                      <div className=" d-flex">
                        <input
                          type="text"
                          name="Coin"
                          id="Coin"
                          className="inp_control"
                          value={`${collateralCoinPrice} USD`}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="col-12 mt-5 d-flex justify-content-center">
                      <button
                        onClick={applyHandler}
                        className="form_submit_btn text-white"
                      >
                        Apply
                      </button>
                    </div>
                  </div>

                  {addContent && (
                    <div className="mt-5">
                      <div className="row gy-4">
                        <div className="col-12 ">
                          {isCopied && (
                            <p className="copyText">Address copied</p>
                          )}

                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Send Collateral Coin to"
                          >
                            Send Collateral Coin to
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Send Collateral Coin to"
                            id="Send Collateral Coin to"
                            className="inp_control"
                            value={wallet?.address}
                            placeholder="Wallet address"
                          />
                          <CopyToClipboard
                            text={wallet?.address}
                            onCopy={() => setisCopied(true)}
                          >
                            <button className="copyButton">Copy</button>
                          </CopyToClipboard>
                        </div>
                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Email Address(Please Enter Carefully)"
                          >
                            Email Address(Please Enter Carefully)
                          </label>
                          <br />
                          <input
                            type="email"
                            name="Email Address(Please Enter Carefully)"
                            id="Email Address(Please Enter Carefully)"
                            className="inp_control"
                            placeholder="Enter your email"
                            value={loanData.email}
                            disabled={otpSent}
                            onChange={(e) =>
                              setloanData({
                                ...loanData,
                                email: e.target.value,
                              })
                            }
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Screen shot of Transfer"
                          >
                            Screen shot of Transfer
                          </label>
                          <br />
                          <Upload onChange={fileChange} src={screenShot}>
                            <div className="d-none d-md-block">
                              Drag & Drop your Files Here
                            </div>
                            <div className="d-block d-md-none">Attach</div>
                          </Upload>
                        </div>
                        <div className="col-12 ">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Loan Receiving Address"
                          >
                            Wallet Address to Receive Crypto
                          </label>
                          <br />
                          <input
                            type="text"
                            placeholder=" Wallet Address to Receive Crypto"
                            name="Loan Receiving Address"
                            id="Loan Receiving Address"
                            className="inp_control"
                            value={loanData.walletAddress}
                            onChange={(e) =>
                              setloanData({
                                ...loanData,
                                walletAddress: e.target.value,
                              })
                            }
                          />
                        </div>
                        {otpSent && !otpVerified && (
                          <OtpBox
                            value={otp}
                            onChange={(value) => setotp(value)}
                            onClick={verifyOtp}
                          />
                        )}

                        <div className="col-12 d-flex align-items-center gap-3">
                          <Checkbox
                            onClick={() => setisChecked(!isChecked)}
                            active={isChecked}
                          />
                          <span className="fw600 text-white f18">
                            Terms & Conditions
                          </span>
                        </div>
                        <div className="col-12 mt-5 d-flex justify-content-center">
                          {otpSent ? (
                            <button
                              className="form_submit_btn text-white"
                              onClick={formSubmit}
                            >
                              Submit Application
                            </button>
                          ) : (
                            <button
                              className="form_submit_btn text-white"
                              onClick={sendOtp}
                            >
                              Send OTP
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <h5 className="text-white mt-2">
                    *For Quick loan repayments or part payments Contact
                    Support@99krypto.com with your transaction ID and email
                    address
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>:Fill the form and check
                    the total to be paid back
                    <br />
                    <span className="color4"> 2</span>: Send the Collateral coin
                    to our specified wallet address
                    <br />
                    <span className="color4"> 3</span>: Take a screenshot of
                    crypto transfer, attach it and submit
                    <br />
                    <span className="color4"> 4</span>: Check your transactional
                    email
                    <br />
                    <span className="color4"> 5</span>: Loan coins will be
                    transferred to your wallet in 2 hours. In few cases it may
                    take longer due to delayed confirmation of your collateral
                    asset
                    <br />
                    <span className="color4"> 6</span>: In some cases we are
                    required to ask you to complete KYC, link will be sent to
                    your email. It just takes 5 mins to complete. Once KYC is
                    completed you will not require to do again as long as you
                    use the same email address.
                    <br />
                    <br />
                    <strong>NOTE</strong>
                    <li>
                      As these are high leveraged loans, it is your
                      responsibility to check the collateral value never falls
                      below total loan to be paid back.
                    </li>
                    <li>
                      If collateral value approaches +5% to the total to be paid
                      back, in that case we may take a call to sell your asset
                      and repay the loan. If any balance left will be
                      transferred to your wallet.
                    </li>
                    <li>
                      Monthly interest payments are not compulsory, how ever you
                      can pay to reduce the risk of liquidation.
                    </li>
                    <li>
                      For part payments or interest payments please contact us
                      at support@99krypto.com with your email address and
                      transaction ID.
                    </li>
                    <br />
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ApplyLend;
