import React from "react";
import OtpInput from "react-otp-input";
import { Link } from "react-router-dom";

const OtpBox = ({ value, onChange, onClick }) => {
  return (
    <>
      <div className="col-12 col-md-6">
        <label
          className="text-white mb-1 fw600"
          htmlFor="Email Address(Please Enter Carefully)"
        >
          Enter your OTP
        </label>
        <br />
        <OtpInput
          value={value}
          onChange={onChange}
          numInputs={4}
          separator={<span>--</span>}
          className="otp-input"
          placeholder="_"
        />
      </div>
      <div className="col-12 col-md-6">
        <label htmlFor=""></label>
        <br />
        <button className="w-100 form_submit_btn2 text-white" onClick={onClick}>
          Verify OTP
        </button>
      </div>
    </>
  );
};

export default OtpBox;
