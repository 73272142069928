import React from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";

const About = () => {
  return (
    <Layout>
      <div className="apply_loan_container">
        <Helmet>
          <title>Press</title>
        </Helmet>
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 about-container">
                <h3>Press : </h3>
                <div className="images">
                  <img src="assets/news/news1.png" alt="" />
                  <img src="assets/news/news2.png" alt="" />
                  <img src="assets/news/news3.png" alt="" />
                  <img src="assets/news/news4.png" alt="" />
                  <img src="assets/news/news5.png" alt="" />
                  <img src="assets/news/news6.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
