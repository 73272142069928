import React from "react";
import { AiOutlineClose } from "react-icons/ai";
import Popup from "reactjs-popup";

const Modal = ({ modalOpen, closeModal }) => {
  return (
    <Popup open={modalOpen} closeOnDocumentClick onClose={closeModal}>
      <div className="modalContent  ">
        <AiOutlineClose className="modalClose" onClick={closeModal} />

        <h4>
          “This website is under Testing and Open for your feedback. Reach out
          to us through contact us form“
        </h4>
        <h4>**Demo transactions are Enabled for testing purpose.</h4>

        <div>
          <button className="btn btn-outline m-2" onClick={closeModal}>
            Close
          </button>
        </div>
      </div>
    </Popup>
  );
};

export default Modal;
