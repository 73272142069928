import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const CashLoans = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const [securityCode, setsecurityCode] = useState("");



  const isBlocked = (item) => {
    return item?.accepted;
  };
  return (
    <div>
      <div className="items">
        <div className="buyCard cashloan">
          <div>Transaction ID</div>
          <div>Status</div>

          <div>Loan Amount</div>
          <div>Loan Currency</div>
          <div>Time Period</div>
          <div>Interest(%)</div>
          <div>Total paid back</div>
          <div>Country</div>
          <div>City</div>
          <div>Take Order</div>
        </div>
        {user?.swaps?.map((item, index) => (
          <div key={index} className="buyCard cashloan">
            <div>
              <strong className="key">Transation ID :</strong>
              <span>{item.transactionId}</span>
            </div>
            <div>
              <strong className="key">Status:</strong>
              <span> {item.status}</span>
            </div>

            <div>
              <strong className="key">Loan Amount:</strong>
              <span>{item.amount}</span>
            </div>
            <div>
              <strong className="key">Loan Currency:</strong>
              <span>{item.currency?.currency} </span>
            </div>
            <div>
              <strong className="key">Time Period:</strong>
              <span>{item.timePeriod?.name} </span>
            </div>
            <div>
              <strong className="key">Interest:</strong>
              <span>{item.timePeriod?.interest} </span>
            </div>
            <div>
              <strong className="key">Total Paid back:</strong>
              <span>{item.totalTobePaid} </span>
            </div>

            <div>
              <strong className="key">Country:</strong>
              <span> {item.country}</span>
            </div>
            <div>
              <strong className="key">City:</strong> <span>{item.city}</span>
            </div>
            {isBlocked(item) ? (
              <div>
                <button className="btn btn-danger">Blocked</button>
              </div>
            ) : (
              <div>
                <button className="btn btn-primary">Take order</button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CashLoans;
