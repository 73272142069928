import React, { useState, useEffect } from "react";
import Layout from "../layout";
import HowItWorks from "../components/HowItWorks";
import Select from "../components/Select";
import Checkbox from "../components/Checkbox";
import Upload from "../components/Upload";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import {
  applyDeposit,
  otpSend,
  otpVerify,
} from "../redux/actions/serviceAction";
import OtpBox from "../components/OtpBox";
import { BsCheckLg } from "react-icons/bs";

const FixedDeposit = () => {
  const dispatch = useDispatch();
  const [isChecked, setisChecked] = useState(false);
  const [addContent, setAddContent] = useState(false);
  const [isCopied, setisCopied] = useState("");
  const [otp, setotp] = useState("");
  const { coins, wallets, timePeriods, success, otpSent, otpVerified } =
    useSelector((state) => state.service);
  const [wallet, setwallet] = useState("");
  const [screenShot, setscreenShot] = useState(null);
  const history = useHistory();

  const [periods, setperiods] = useState([]);
  const [depositData, setdepositData] = useState({
    depositingCoin: null,
    email: "",
    timePeriod: null,
    receivingAddress: "",
    totalTobeReceived: "",
  });

  useEffect(() => {
    setwallet(
      wallets.find((wal) => wal.coin?._id == depositData?.depositingCoin?._id)
    );
  }, [depositData.depositingCoin]);

  const clickHandler = () => {
    if (!depositData?.depositingCoin?.name) {
      return toast.warn("Please select Coin");
    }
    if (!depositData.amount) {
      return toast.warn("Please add amount");
    }
    if (!depositData?.timePeriod?.interest) {
      return toast.warn("Please select Time Period");
    }
    if (!depositData?.email) {
      return toast.warn("Please enter your email");
    }

    setAddContent(true);
  };

  useEffect(() => {
    if (!depositData.depositingCoin) return;
    const _periods = timePeriods.filter(
      (P) => P.coin._id == depositData.depositingCoin?._id && P.for == "deposit"
    );

    console.log(_periods);

    setperiods(_periods);
    setdepositData({ ...depositData, timePeriod: { interest: "" } });
  }, [depositData.depositingCoin]);

  const calTotal = () => {
    if (!depositData.amount) return;
    if (!depositData.timePeriod?.numberOfMonths) return;

    const P = parseFloat(depositData.amount).toFixed(18);
    const r = depositData?.timePeriod?.interest * 0.01;
    const t = parseInt(depositData.timePeriod?.numberOfMonths) / 12;
    const total = P * (1 + r * t);

    setdepositData({
      ...depositData,
      totalTobeReceived: total.toFixed(18),
    });
  };

  useEffect(() => {
    calTotal();
  }, [depositData.amount, depositData.timePeriod, depositData.depositingCoin]);

  const formSubmit = (e) => {
    e.preventDefault();
    if (!depositData.receivingAddress) {
      return toast.warn("Receiving Address is required");
    }
    if (!screenShot) {
      return toast.warn("Screenshot is required");
    }
    if (!isChecked) {
      return toast.warn("Please check mark the Terms & Conditions");
    }
    if (!otpVerified) {
      return toast.warn("Please verify your email");
    }

    const form = new FormData();
    form.set("email", depositData.email);
    form.set("timePeriod", depositData.timePeriod._id);
    form.set("amount", depositData.amount);
    form.set("recieving", depositData.totalTobeReceived);
    form.set("receivingAddress", depositData.receivingAddress);
    form.set("depositingCoin", depositData.depositingCoin._id);
    form.set("screenShot", screenShot);

    dispatch(applyDeposit(form));
  };

  const fileChange = (e) => {
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        // setAvatarPreview(reader.result);
        setscreenShot(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  useEffect(() => {
    if (success) {
      return history.push("/submit-thankyou");
    }
  }, [success]);

  const sendOtp = (e) => {
    e.preventDefault();

    dispatch(otpSend({ email: depositData.email }));
  };

  const verifyOtp = (e) => {
    e.preventDefault();

    dispatch(otpVerify({ email: depositData.email, otp }));
  };

  return (
    <Layout title="Fixed Deposit || 99 Krypto">
      <div className="apply_loan_container">
        <div className="page_container">
          <div className="container-fluid">
            <div className="row gy-4">
              <div className="col-12 col-lg-8">
                <div className="apply_loan_right_container p-4">
                  <h1 className="mb-3 text-white display-5 fw500">Deposit</h1>
                  <h4 className="text-white ">
                    <BsCheckLg className="m-2" />
                    Earn Interest on Crypto Fixed Term Deposits
                  </h4>
                  <h4 className="text-white">
                    <BsCheckLg className="m-2" /> Open Dealer account with your
                    Deposit{" "}
                  </h4>
                  <h4 className="text-white">
                    <BsCheckLg className="m-2" /> Interest and principal paid at
                    settlement{" "}
                  </h4>
                  <h4 className="text-white">
                    <BsCheckLg className="m-2" />
                    Any time loan on your Deposit Account
                  </h4>

                  <br />
                  <div className="row gy-4">
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Depositing Coin"
                      >
                        Depositing Coin
                      </label>
                      <Select
                        activeOption={depositData.depositingCoin?.name}
                        options={coins}
                        property="name"
                        property2={"code"}
                        img="253"
                        dataChange={(item) => {
                          setdepositData({
                            ...depositData,
                            depositingCoin: item,
                          });
                          setwallet(wallets.find((w) => item._id === w.coin));
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Loan Amount"
                      >
                        Quantity
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Loan Amount"
                        id="Loan Amount"
                        className="inp_control"
                        placeholder="Enter quantity"
                        value={depositData.amount}
                        onChange={(e) =>
                          setdepositData({
                            ...depositData,
                            amount: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Coin"
                      >
                        Deposit Period
                      </label>
                      <Select
                        activeOption="2 Months"
                        options={periods}
                        property="name"
                        dataChange={(item) =>
                          setdepositData({ ...depositData, timePeriod: item })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Collateral Required"
                      >
                        Interest % APY
                      </label>
                      <br />
                      <input
                        type="text"
                        name="Collateral Required"
                        id="Collateral Required"
                        className="inp_control"
                        value={`${
                          depositData.timePeriod?.interest
                            ? depositData.timePeriod?.interest
                            : ""
                        }  %`}
                        placeholder="Interest rate"
                        disabled
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label
                        className="text-white mb-1 fw600"
                        htmlFor="Email Address"
                      >
                        Email Address
                      </label>
                      <input
                        type="email"
                        name="Collateral Required"
                        id="Collateral Required"
                        className="inp_control"
                        placeholder="Enter your email"
                        value={depositData.email}
                        disabled={otpSent}
                        onChange={(e) =>
                          setdepositData({
                            ...depositData,
                            email: e.target.value,
                          })
                        }
                      />
                    </div>
                    <div className="col-12 col-md-6">
                      <label className="text-white mb-1 fw600" htmlFor="Coin">
                        Total to be Received @Maturity
                      </label>
                      <br />
                      <input
                        type="number"
                        name="Coin"
                        id="Coin"
                        className="inp_control"
                        value={depositData.totalTobeReceived}
                        disabled={true}
                      />
                    </div>

                    <div className="col-12 mt-5 d-flex justify-content-center">
                      <button
                        onClick={clickHandler}
                        className="form_submit_btn text-white"
                      >
                        Continue
                      </button>
                    </div>
                  </div>

                  {addContent && (
                    <div className="mt-5">
                      <div className="row gy-4">
                        <div className="col-12">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Recieving Adress@Maturity"
                          >
                            Recieving Adress@Maturity
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Recieving Adress@Maturity"
                            id="Recieving Adress@Maturity"
                            className="inp_control"
                            placeholder="Receiving aaddress..."
                            value={depositData?.receivingAddress}
                            onChange={(e) =>
                              setdepositData({
                                ...depositData,
                                receivingAddress: e.target.value,
                              })
                            }
                          />
                        </div>
                        {isCopied && <p className="copyText">Address copied</p>}

                        <div className="col-12 ">
                          <label
                            className="text-white mb-1 fw600"
                            htmlFor="Send Crypto to"
                          >
                            Send Crypto to
                          </label>
                          <br />
                          <input
                            type="text"
                            name="Send Crypto to"
                            id="Send Crypto to"
                            className="inp_control"
                            value={wallet?.address}
                            disabled={true}
                          />
                          <CopyToClipboard
                            text={wallet?.address}
                            onCopy={() => setisCopied(true)}
                          >
                            <button className="copyButton">Copy</button>
                          </CopyToClipboard>
                        </div>
                      </div>
                      <div className="col-12 col-md-6">
                        <label
                          className="text-white mb-1 fw600"
                          htmlFor="Screenshot of transfer"
                        >
                          Screenshot of transfer
                        </label>
                        <br />
                        <Upload onChange={fileChange} src={screenShot}>
                          <div className="d-none d-md-block">
                            Drag & Drop your Files Here
                          </div>
                          <div className="d-block d-md-none">Attach</div>
                        </Upload>
                      </div>
                      {otpSent && !otpVerified && (
                        <OtpBox
                          value={otp}
                          onChange={(value) => setotp(value)}
                          onClick={verifyOtp}
                        />
                      )}

                      <div className="col-12 my-20 py-10 col-md-6 d-flex align-items-center gap-3">
                        <Checkbox
                          onClick={() => setisChecked(!isChecked)}
                          active={isChecked}
                        />
                        <span className="fw600 text-white f18">
                          Terms & Conditions
                        </span>
                      </div>
                      <div className="col-12 mt-5 d-flex justify-content-center">
                        {!otpSent && (
                          <button
                            className="form_submit_btn text-white"
                            onClick={sendOtp}
                          >
                            Send OTP
                          </button>
                        )}
                        {otpVerified && (
                          <button
                            className="form_submit_btn text-white"
                            onClick={formSubmit}
                          >
                            Submit
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                  <h5 className="text-white m-2">
                    *For assistance or enquiries contact Support@99krypto.com
                  </h5>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <HowItWorks>
                  <div className="p-4 pt-0 color3 fw500">
                    <span className="color4"> 1</span>: Fill the form and check
                    the total to be received at the time of Maturity
                    <br />
                    <span className="color4"> 2</span>: Send the deposit coin to
                    our specified wallet adress
                    <br />
                    <span className="color4"> 3</span>:Take a screenshot of
                    crypto transfer, attach it and submit
                    <br />
                    <span className="color4"> 4</span>: Check your transactional
                    email
                    <br />
                    <span className="color4"> 5</span>:After maturity, coins
                    will be transferred to your wallet. which is provided at the
                    time of deposit, If any change in receiving wallet address,
                    do inform us prior to the maturity date.
                    <br />
                    <span className="color4"> 6</span>:In some cases we are
                    required to ask you to complete KYC, link will be sent to
                    your email. It may take 5 mins to complete KYC. Once KYC is
                    completed you will not require to do it again as long as you
                    use the same email address
                    <br />
                    <br />
                    <strong>NOTE</strong>
                    <li>
                      All our deposits are fixed term deposits, hence can’t be
                      liquidated before.
                    </li>
                    <li>
                      For Deposits opened by Dealers as security for
                      transactions can be cancelled any time, however in that
                      case only principle is credited and interest will not be
                      accumulated
                    </li>
                    <li>
                      You are allowed to take loan on your Deposit keeping it as
                      collateral. For more information contact our support with
                      your deposit transaction id
                    </li>
                    <li>
                      Principal and interest will be credited to your wallet
                      after completion of maturity period, how ever you can
                      contact us prior to maturity date for any changes in
                      receiving wallet address.
                    </li>
                  </div>
                </HowItWorks>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default FixedDeposit;
